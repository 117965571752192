import { render, staticRenderFns } from "./HeaderLayout.vue?vue&type=template&id=04c319cd&scoped=true"
import script from "./HeaderLayout.vue?vue&type=script&lang=js"
export * from "./HeaderLayout.vue?vue&type=script&lang=js"
import style0 from "./HeaderLayout.vue?vue&type=style&index=0&id=04c319cd&prod&lang=css"
import style1 from "./HeaderLayout.vue?vue&type=style&index=1&id=04c319cd&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/.store/vue-loader@15.11.1/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "04c319cd",
  null
  
)

export default component.exports
export default {
    header: {
        hello: 'Hello World!',
        changeLanguage: 'Language',
        Login: 'Sign in',
        Register: 'Register',
        Logout: 'Logout',
        shouye: 'Home',
        bibijiaoyisuo: 'Spot',
        heyuejiaoyisuo: 'Futures',
        licaichanpin: 'Mining Pool',
        rengou: 'subscription',
        ruanjianxiazai: 'Software download',
        zhongwen: '中文',
        yingwen: 'En',
        zichan: 'assets',
        dingdan: 'order',
        heyuedingdan: 'Contract Order',
        bibidingdan: 'Crypto Order',
        anquanzhongxin: 'Security Center',
        fanyongyaoqing: 'Rebate Invitation',
        tuichudenglu: 'Logout',
        gupiaojiaoyi: 'Stock',
        waihuijiaoyi: 'FX',
        qiquanjiaoyi:'Options',
        gupiaodingdan: 'Stock Order',
        waihuidingdan: 'FX Order',
        qiquandingdan: 'Options Order',
    },
    home: {
        huanyinglaidao: 'Welcome to',
        pingtaimingcheng: 'Here',
        mengxiangqihang: 'Dream departure',
        yijiankaiqishuzihualvcheng: 'Start your digital journey with one click',
        liaojiegengduo: 'Learn More',
        chongzhi: 'recharge',
        jiaoyi: 'trade',
        remenbang: 'Hot',
        zhangfubang: 'Top Gainer',
        diefubang: 'Top Loser',
        chengjiaoliang: 'Top Volume',
        zanwushuju: 'no data',
        jiayidui: 'Symbol',
        zuixinjiage: 'Last Price',
        zhangdie24h: '24HChange',
        caozuo: 'Action',
        jiaoyi: 'Trade',
        gengduo: 'More',
        suishisuidikaiqijiaoyi: 'Start trading anytime, anywhere',
        wulunshiApphaishiwebdoukeyikuaisukaiqinindejiaoyi: 'Whether it’s App or Web, you can quickly start your transaction',
        saomaxiazaiApp: 'Scan to download APP',
        iosheAndroid: 'ios&Android',
        ninkexinrenqieanquandejiamibijiaoyisuo: 'Your trusted and secure cryptocurrency exchange',
        tishi1: 'Reserve Certificate',
        xiangqing1: 'We promise that MBEX will hold all user assets at least in a ratio of 1:1',
        tishi2: 'Best transaction rates',
        xiangqing2: 'Preferential transaction rates, competitive VIP rights, and enjoy the best quality services',
        tishi3: 'Security you can count on',
        xiangqing3: 'Our sophisticated security measures and SAFU funds protect your digital assets from all risks',
        tishi4: '24*7 hours customer service support',
        xiangqing4: '24*7 hours all-weather, full-time operation mode, answer your relevant inquiries as soon as possible',
        tishi5: 'Stable and safe',
        xiangqing5: 'The world’s top ten exchanges, providing bank-level security protection for your assets',
        tishi6: 'common problem',
        xiangqing6: 'Check out the FAQ for detailed descriptions of specific features',
    },
    footer: {
        bangzhuzhongxin: 'Help center',
        dianhuazixun: 'Telephone consultation',
        dianhuahegongzuoshijian: '1670-9756(Rest on weekends and holidays)',
        liaotianzixun: '1:1 Chat consultation',
        keketuozixun: 'Coco tok consulting',
        zaixianbangzhu: 'Online help',
        h24kaifang: '(Open 24 hours)',
        lixianbangzhu: 'Offline help',
        gongsidizhi: '1 / F, Longshan purgio summit, 69 Hanjiang Avenue, Longshan District, Seoul',
        gongzuoshijian: '(10:00~18:00 / Rest on weekends and holidays)',
        shangshizixun: 'Listing consultation',
        hezuozixun: 'Cooperation consultation',
        gongsi: 'company',
        lianxiwomwn: 'Contact us',
        zhidao: 'guidance',
        yonghuzhichi: 'User support',
        zhanghuzhinan: 'Account Guide-Recharge/withdraw coins',
        ruanjianxiazai: 'Download',
        zhengce: 'policy',
        fuwuyuyinsitiaokuan: 'Service and Privacy Terms',
    },
    login: {
        yonghudenglu: 'User login',
        huanyingdenglu: 'Welcome login',
        xiangmumingcheng: 'SGCEX',
        guanfangwangzhan: 'official website',
        youxiang: 'Email',
        qingshuruyouxiang: 'Please enter your email',
        mima: 'password',
        qingshurumima: 'Please enter the password!',
        wangjimima: 'Forgot password?',
        denglu: 'Login',
        haimeiyouzhanghao: 'No account?Go',
        zhuce: 'registered',
        dengluchenggong: 'Login successful',
        denglushibai: 'Login failed',
        qingshuruyonghuminghemima: 'Please enter your username and password',
        mimachangdubunengxiaoyuliuwei: 'The length of the password cannot be less than 6 characters',
        youxiangrenzheng: 'Email authentication',
        youxiangdizhi: 'Email address',
        qingshuruyouxiangdizhi: 'Please enter the email address',
        tuxingyanzhengma: 'Captcha',
        qingshurutuxingyanzhengma: 'Please enter the graphic verification code',
        yanzhengma: 'Verification code',
        qingshuruyouxiangyanzhengma: 'Please enter email verification code',
        huoquyouxiangyanzhengma: 'Email verification code',
        queding: 'determine',
        huoqutuxingyanzhengmashibaiqingchongxinqingqiu: 'Failed to obtain verification code, please re request',
        qingxianwanchengyouxiangrenzheng: 'Please complete email authentication first',
        huoquyouxiangyanzhengmashibai: 'Failed to obtain email verification code,',
        houchongxinfasong: 'S Resend after',
        youxiangyanzhengchenggong: 'The email address was verified successfully',
        yanzhengmacuowu: 'verification code incorrect',
        xiugaidenglumima: 'Modify the login password',
        xinmima: 'New password',
        quedingxinmima: 'Make sure new password',
        qingshuruxinmima: 'Please enter new password',
        qingzaicishuruxinmima: 'Please enter new password again',
        liangcimimashurubuyizhi: 'The password is entered inconsistently',
        mimaxiugaichenggong: 'The password was successfully changed',
        xiugaimimashibai: 'Password change failed',
    },
    trade: {
        hangqingheyue: 'Contract',
        hangqingzuixinjia: 'Latest price',
        hangqingzhangdiefu: 'Fluctuation range',
        hangqingzuigao24h: '24h max',
        hangqingzuidi24h: '24h min',
        hangqingchengjiaoliang24h: '24h trading volume',
        hangqingchengjiabishu: 'Number of transactions',
        zhanghuquanyi: 'Account equity',
        zhanghukeyongshouyi: 'Available equity',
        hangqingweituoliebiao: 'Delegate list',
        hangqingjiage: 'Price',
        hangqingshuliang: 'quantity',
        hangqingzuixinchengjiao: 'Latest transaction',
        hangqingjiage1: 'Price',
        hangqingshuliang1: 'quantity',
        hangqingshijian: 'time',
        dingdanchiyoucangwei: 'Hold position',
        dingdandangqianweituo: 'Current delegation',
        dingdanchengjiaojilu: 'Transaction record',
        dingdantiaojiandan: 'Condition sheet',
        chicangheyue: 'contract',
        chicangkaicangjunjia: 'Opening price',
        chicangfudongyingkui: 'Floating P/L(USDT)',
        chicanggangganbeishu: 'Leverage multiple',
        chicangchicangliang: 'open interest',
        chicangduokong: 'long/short',
        chicangduo: 'long',
        chicangkong: 'short',
        chicangzhiyingjiage: 'stop Profit',
        chicangzhisunjiage: 'stop Loss',
        chicangqiangpingyugujiage: 'Forced closing price',
        chicangcaozuo: 'operation',
        chicangzhiyingzhisun: 'stop limit',
        chicangpingcang: 'close',
        chicangshouxufei: 'fee',
        weituoheyue: 'Contract',
        weituoshuliang: 'Entrusted quantity',
        weituojiage: 'Entrusted price',
        weituofangxiang: 'Direction',
        weituogangganbeishu: 'Leverage multiple',
        weituochengjiaoshuliang: 'Transaction quantity',
        weituoshouxufei: 'Fee',
        weituochengjiaojunjia: 'Average price',
        weituoshouyi: 'Profit',
        weituozhuangtai: 'State',
        weituochengjiaoshijian: 'Transaction time',
        weituocaozuo: 'Operation',
        weituochedan: 'Cancel',
        weituotijiaoshibai: 'Submission failed',
        weituozhilingtijiao: 'Instruction submission',
        weituoyitijiao: 'Submitted',
        weituobufenchengjiao: 'Partial transaction',
        weituobufenchengjiaoyichedan: 'Partial transaction',
        weituowanquanchengjiao: 'Complete deal',
        weituowanquanchedan: 'Complete cancellation',
        weituochedanzhong: 'Cancellation in progress',
        weituokaiduo: 'open long',
        weituopingkong: 'close short',
        weituokaikong: 'open short',
        weituopingduo: 'close long',
        chengjiaoheyue: 'contract',
        chengjiaofangxiang: 'direction',
        chengjiaoshuliang: 'Number of transactions',
        chengjiaojiage: 'Transaction price',
        chengjiaoshijian: 'Transaction time',
        chengjiaopingcangyingkui: 'Closing profit and loss',
        chengjiaoshouxufei: 'Transaction fee',
        chengjiaokaiduo: 'open long',
        chengjiaopingkong: 'close short',
        chengjiaokaikong: 'open short',
        chengjiaopingduo: 'close long',
        tiaojianzengjiatiaojian: 'Add condition',
        tiaojianzantingquanbu: 'Pause all',
        tiaojianqidongquanbu: 'Start all',
        tiaojianshanchuquanbu: 'Delete all',
        tiaojianheyue: 'contract',
        tiaojianweituoshuliang: 'Entrusted quantity',
        tiaojianfangxiang: 'direction',
        tiaojiantiaojianleixing: 'Condition type',
        tiaojianjiageleixing: 'Price type',
        tiaojianbijiaofuhao: 'Symbol',
        tiaojiantiaojian: 'condition',
        tiaojianzhuangtai: 'state',
        tiaojianchuangjianshijian: 'Creation time',
        tiaojiancaozuo: 'operation',
        tiaojianxiugai: 'modify',
        tiaojianqidong: 'start-up',
        tiaojianzanting: 'suspend',
        tiaojianshanchu: 'delete',
        tiaojianlijixiadan: 'Order now',
        tiaojiankaiduo: 'open long',
        tiaojianpingkong: 'close short',
        tiaojiankaikong: 'open short',
        tiaojianpingduo: 'close long',
        tiaojianjiagetiaojian: 'Price conditions',
        tiaojianshijiantiaojian: 'Time conditions',
        tiaojianzuixinjia: 'Latest price',
        tiaojianByijia: 'Buy one price',
        tiaojianSyijia: 'Sell one price',
        tiaojianzanting: 'suspend',
        tiaojianyunxing: 'run',
        tiaojianyiwancheng: 'Completed',
        tiaojianshibai: 'Fail',
        tiaojianyishanchu: 'Deleted',
        tiaojianshoudongchufawancheng: 'Manual trigger complete',
        tiaojianshoudongchufashibai: 'Manual trigger failed',
        xiadankaicang: 'open',
        xiadanpingcang: 'close',
        xiadanweituoleixing: 'Delegate type',
        xiadanqingxuanze: 'Please select',
        xiadanshijia: 'market price',
        xiadanxianjia: 'fixed price',
        xiadanjiage: 'price',
        xiadanqingshurujiage: 'Please enter price',
        xiadanshuliang: 'quantity',
        xiadanqingshurushuliang: 'Please enter quantity',
        xiadanzhang: 'amount',
        xiadankekaishuliang: 'Quantity available',
        xiadanqingxianxuanzechicangdan: 'Select the position sheet first',
        xiadankepingshuliang: 'Flat quantity',
        xiadansuoxubaozhengjin: 'Required margin:',
        xiadanshijiazhiyingzhisunyushe: 'Market price profit stop loss preset',
        xiadanyujiqiangpingjiage: 'Liquidation Estimated Price:',
        xiadanqiangpingjiagetishi: 'This estimated strong parity is calculated based on the dynamics of total assets. This price is for reference only and will not be used as final leveling.',
        xiadanzuoduo: 'Buy long',
        xiadanzuokong: 'Buy short',
        xiadanpingcang: 'close',
        zhanghuheyuezichan: 'Contract assets',
        zhanghuchongbi: 'Charge',
        zhanghutibi: 'Withdraw',
        zhanghuhuazhuan: 'Transfer',
        zhanghuzhanghuquanyi: 'Account equity',
        zhanghufudongyingkui: 'Floating P/L',
        zhanghucangweibaozhengjin: 'Position margin',
        zhanghudongjiebaozhengjin: 'Frozen deposit',
        zhanghukeyongquanyi: 'Available equity',
        zhanghubaozhengjinshiyonglv: 'Margin utilization',
        hangqingheyuexinxi: 'Contract information',
        hangqingheyuemingcheng: 'Contract name',
        hangqingjijiahuobi: 'Valuation currency',
        hangqingheyuedaxiao: 'Contract size',
        hangqingzuixiaojiagebodong: 'Minimum price fluctuation',
        dialogleverRateganggantiaozheng: 'Lever adjustment',
        dialogleverRatequeding: 'determine',
        dialogtransferzichanhuazhuan: 'Asset transfer',
        dialogtransferbizhong: 'currency',
        dialogtransfercong: 'from',
        dialogtransferdao: 'to',
        dialogtransfershuliang: 'quantity',
        dialogtransferqingshuruhuazhuanshuliang: 'Please enter transfer quantity',
        dialogtransferquanbu: 'whole',
        dialogtransferkeyong: 'available',
        dialogtransferhuazhuan: 'Transfer',
        dialogtransferjichuzhanghu: 'Basics Account',
        dialogtransferheyuezhanghu: 'Contract Account',
        dialogfitlosszhiyingzhisun: 'Stop profit and stop loss',
        dialogfitlossheyu: 'contract',
        dialogfitlossduokong: 'long/short',
        dialogfitlosschichangjunjia: 'Average position price',
        dialogfitlossshuliang: 'quantity',
        dialogfitlosszuixinjia: 'Latest price',
        dialogfitlosszhiyingdianshu: 'P points',
        dialogfitlosszhisundianshu: 'L points',
        dialogfitlossdianshu: 'points',
        dialogfitlosszhiyingjiage: 'P price',
        dialogfitlosszhisunjiage: 'L price',
        dialogfitlossjiage: 'price',
        dialogfitlossyuqishouyi: 'Expected income',
        dialogfitlossyuqikuisun: 'Expected loss',
        dialogfitlosstishi1: 'Tip: the minimum fluctuation of the current contract is',
        dialogfitlosstishi2: '. Please set it to 0 to cancel the profit stop and loss stop',
        dialogfitlossqueding: 'determine',
        dialogfitlossduo: 'long',
        dialogfitlosskong: 'short',
        dialogconditionzengjiayuntiaojiandan: 'Add condition',
        dialogconditiontiaojianleixing: 'Condition type:',
        dialogconditionjiagetiaojiandan: 'Price term sheet',
        dialogconditionshijiantiaojiandan: 'Time condition sheet',
        dialogconditionjiageleixing: 'Price type:',
        dialogconditionzuixinjia: 'Latest price',
        dialogconditionByijia: 'Buy a price',
        dialogconditionSyijia: 'Sell for one price',
        dialogconditiontiaojianshezhi: 'Condition setting:',
        dialogconditionjiageshezhi: 'Price setting:',
        dialogconditionqingshurujiage: 'Please enter price',
        dialogconditionshijianshezhi: 'time setting:',
        dialogconditionjiaoyileixing: 'Transaction type:',
        dialogconditionkaiduo: 'open long',
        dialogconditionpingkong: 'close short',
        dialogconditionkaikong: 'open short',
        dialogconditionpingduo: 'close long',
        dialogconditiondingdanshuliang: 'Order quantity:',
        dialogconditionqingshurudingdanshuliang: 'Please enter order quantity',
        dialogconditiondingdanshixiao: 'Order aging:',
        dialogconditionyongjiuyouxia: 'Permanently valid',
        dialogconditiontijiao: 'Submit',
        dialogAutoProfitLosszhiyingzhisun: 'Stop profit and stop loss',
        dialogAutoProfitLossmingcheng: 'name',
        dialogAutoProfitLosszhiyingdian: 'stop profit point',
        dialogAutoProfitLosszhisundian: 'Stop loss point',
        dialogAutoProfitLosschongzhi: 'Reset',
        dialogAutoProfitLosstijiao: 'Submit',
        dialogCloseOrderpingcang: 'close',
        dialogCloseOrderweituoleixing: 'Delegate type',
        dialogCloseOrderqingxuanze: 'Please select',
        dialogCloseOrdershijia: 'market price',
        dialogCloseOrderxianjia: 'fixed price',
        dialogCloseOrderjiage: 'price',
        dialogCloseOrderqingshurujiage: 'Please enter price',
        dialogCloseOrdershuliang: 'quantity',
        dialogCloseOrderqingshurushuliang: 'Please enter quantity',
        dialogCloseOrderzhang: 'amount',
        dialogCloseOrderkepingshuliang: 'Flat quantity',
        dialogCloseOrderqueding: 'determine',
        jsMessageResgaiheyuegangganxiugaichenggong: 'The contract leverage has been successfully modified',
        jsMessageResgaiheuyegangganxiugaishibai: 'Failed to modify the leverage of the contract',
        jsMessageReskaicangchenggong: 'Position opened successfully',
        jsMessageReskaicangshibai: 'Opening position failed:',
        jsMessageReskaicang1: 'Prohibit opening warehouses',
        jsMessageReskaicang2: 'contract does not exist',
        jsMessageReskaicang3: 'opening quantity exceeds the upper limit',
        jsMessageReskaicang4: 'The price is 0',
        jsMessageReskaicang5: 'Insufficient opening margin',
        jsMessageRespingcangchenggong: 'Position closed successfully',
        jsMessageRespingcangshibai: 'Failed to close position:',
        jsMessageRespingcang1: 'Contract does not exist',
        jsMessageRespingcang2: 'Position order does not exist',
        jsMessageRespingcang3: 'Insufficient leveling capacity',
        jsMessageReszijinhuazhuanchenggong: 'Funds transferred successfully',
        jsMessageReszijinhuazhuanshibai: 'Funds transfer failed',
        jsMessageReschicangdanzhiyingzhisunshezhichenggong: 'Successfully set P/L for position orders',
        jsMessageReschicangdanzhiyingzhisunshezhishibai: 'Failed to set P/L for position order:',
        jsMessageResweituodanchedanchenggong: 'Successfully cancelled the entrustment order',
        jsMessageResweituodanchedanshibai: 'Failed to cancel the entrustment order',
        jsMessageRestiaojiandantianjiachenggong: 'Condition order added successfully',
        jsMessageResheyuebucunzai: 'Contract does not exist',
        jsMessageResjiageshezhicuowu: 'Price setting error',
        jsMessageResshijianshezhicuowu: 'Time setting error',
        jsMessageResneibucuowu: 'Internal error',
        jsMessageRestiaojiandantianjiashibai: 'Failed to add conditional order:' ,
        jsMessageReszhiyingzhisunshezhichenggong: 'Take profit and stop loss set successfully',
        jsconfirmquedingchedanma: 'Are you sure to cancel the order?',
        jsconfirmtishi: 'Prompt',
        jsconfirmqueding: 'OK',
        jsconfirmquxiao: 'Cancel',
        jsMessageqingxiandenglu: 'Please log in first',
        jsMessagegaiheyueyouchicangbukexiugaiganggan: 'The contract has a position, and the leverage cannot be modified',
        jsMessagegaiheyueyouweituobukexiugaiganggan: 'The contract has a entrustment, and the leverage cannot be modified',
        jsMessageqingshuruzhengquedeshuliang: 'Please enter the correct quantity!',
        jsMessageqingshuruzhengquedejiage: 'Please enter the correct price!',
        jsMessageqingxianxuanzechicang: 'Please select the position sheet first',
        jsMessageqingshuruhuazhuanshuliang: 'Please enter the transfer amount!',
        jsMessageqingxianxuanzeheyue: 'Please select a contract first!',
        jsMessageqingshurutiaojiandanshuliang: 'Please enter the correct quantity!',
        jsMessageqingshurutiaojiandandejiage: 'Please set the correct price!',
        jsMessageqingshurutiaojiandanzhixingshijian: 'Please set the time!',
        emptytext: 'no data',
        jsMessageReszhiyingzhisundianshushezhicuowu: 'Error in setting take profit and stop loss points',
        jsMessageReschicangdanbucunzai: 'Position order does not exist',
    },
    assets: {
        zongzichan: 'Total Assets',
        chongbi: 'Charge',
        tibi: 'Withdraw',
        huazhuan: 'Transfer',
        fabizhanghu: 'Basics Account',
        heyuezhanghu: 'Contract Account',
        jiayiliushui: 'Transaction Record',
        dialogzichanhuazhuan: 'Asset transfer',
        cong: 'from',
        dao: 'to',
        shuliang: 'quantity',
        qingshuruhuazhuanshuliang: 'Please enter transfer quantity',
        quanbu: 'whole',
        keyong: 'available',
        huanzhuan: 'Transfer',
        zijinhuazhuanchenggong: 'Funds transferred successfully',
        zijinhuazhuanshibai: 'Funds transfer failed:',
        bibizhanghu: 'Coin Account',
        qiquanzhanghu:'Options Account'
    },
    basicsAccountRecord: {
        fanhui: 'Back',
        fabizhanghuliushui: 'Basics Account Record',
        fabijiaoyiliushuileixing: 'Basics Account Record type',
        qingxuanze: 'Please choose',
        chaxunriqi: 'Query date',
        liushuileixing: 'type',
        shijian: 'Time',
        biandongjine: 'change amount',
        yue: 'balance',
        quanbu: 'whole',
        Sellbi: 'Sell',
        Buybi: 'Buy',
        tibi: 'Withdraw',
        chongbi: 'Charge',
        fabizhuanheyue: 'Basics Account to Contract Account',
        heyuezhuanfabi: 'Contract Account to Basics Account',
        zhuanzhang: 'transfer accounts',
        fabihuazhuandaoheyue: 'Basics Account to Contract Account',
        heyuehuazhuandaofabi: 'Contract Account to Basics Account',
        goumaijijin: 'Purchase Coin fund',
        jijinshuhui: 'Redemption Coin fund',
        jijinshouyi: 'Coin fund income',
        fabizhuanqihuo: 'Basics Account to Futures Account',
        qihuozhuanfabi: 'Futures Account to Basics Account',
        fabizhuanganggu: 'Basics Account to HK stocks',
        gangguzhuanfabi: 'HK stocks to Basics Account',
        fabizhuanbibi: 'Basics Account to Coin',
        bibizhuanfabi: 'Coin to Basics Account',
        goumaiSGCB: 'Buy ICO',
        chushouSGCB: 'Sell ICO',
        huoqushibai: 'Acquisition failed',
        zanwushuju: 'No data',
    },
    contractAccountRecord: {
        fanhui: 'Back',
        heyuezhanghuliushui: 'Contract Account Record',
        heyuejiaoyiliushuileixing: 'Contract Account Record type',
        qingxuanze: 'Please choose',
        chaxunriqi: 'Query date',
        liushuileixing: 'type',
        shijian: 'Time',
        biandongjine: 'change amount',
        yue: 'balance',
        quanbu: 'whole',
        kaicangshouxufei: 'Opening fee',
        pingcangshouxufei: 'Closing fee',
        pingcangyingkui: 'Closing profit and loss',
        fabizhuanheyue: 'Basics Account to Contract Account',
        heyuezhuanfabi: 'Contract Account to Basics Account',
        zhuanzhang: 'transfer accounts',
        kaicangyongjin: 'Opening commission',
        pingcangyongjin: 'Closing Commission',
        huoqushibai: 'Acquisition failed',
        zanwushuju: 'No data',
    },
    recharge: {
        zanwushuju: 'No data',
        shijian: 'time',
        leixing: 'type',
        shuliang: 'quantity',
        zhuangtai: 'state',
        caozuo: 'operation',
        chakanxiangqing: 'View details',
        huoqushibai: 'Acquisition failed',
        dialogtitle: 'details',
        chongbi: 'recharge',
        fanhui: 'Back',
        lianmingcheng: 'Chain name',
        fuzhilianjie: 'copy address',
        tishixinxi1: '• Do not recharge any non USDT assets to the above address, otherwise the assets will not be recovered.',
        tishixinxi2: '• After you recharge to the above address, you need the confirmation of the whole network node. You can arrive after one network confirmation and withdraw money after two network confirmations.',
        tishixinxi3: '• Minimum recharge amount: 50 USDT. Recharge less than the minimum amount will not be posted and cannot be returned.',
        tishixinxi4: '• Your recharge address will not change frequently, and you can recharge repeatedly; If there is any change, we will try our best to notify you through website announcement or email.',
        tishixinxi5: '• Please make sure that the computer and browser are secure to prevent information from being tampered with or disclosed',
        putongchongbi: 'Ordinary coin charging',
        jiaoyichenggong: 'Successful trade',
        jiaoyishibai: 'Transaction failed',
        OMNIweihuzhong: 'In OMNI chain maintenance, please select trc20',
    },
    withdraw: {
        fanhui: 'Back',
        zanwushuju: 'No data',
        tibi: 'withdraw',
        huoqushibai: 'Acquisition failed',
        OMNIweihuzhong: 'In OMNI chain maintenance, please select trc20',
        shijian: 'time',
        leixing: 'type',
        shuliang: 'quantity',
        zhuangtai: 'state',
        caozuo: 'operation',
        chakanxiangqing: 'View details',
        putongtibi: 'Ordinary withdrawal',
        dialogtitle: 'details',
        dialogtitlewithdraw: 'withdrawal',
        houchongxinfasong: 'S Resend after',
        huoquyouxiangyanzhengma: 'Email verification code',
        lianmingcheng: 'Chain name',
        tibidizhi: 'Withdrawal address',
        qingshurutibidizhi: 'Please enter the withdrawal address',
        tibishuliang: 'Withdrawal quantity',
        shouxufei: 'fee',
        qingshurutibishuliang: 'Please enter the withdrawal quantity',
        quanbu: 'whole',
        keyong: 'available',
        daozhangshuliang: 'Received quantity',
        zuixiaotibishuliang: 'The minimum withdrawal quantity is:',
        tishixinxi: 'In order to ensure fund security, when your account security policy is changed or your password is changed, we will manually review the withdrawal. Please wait patiently for the staff to contact by phone or email. Please make sure that the computer and browser are secure to prevent information from being tampered with or disclosed.',
        tuxingyanzhengma: 'Captcha',
        qingshurutuxingyanzhengma: 'Please enter the graphic verification code',
        youxiangyanzhengma: 'Email verification code',
        qingshuruyouxiangyanzhengma: 'Please enter email verification code',
        queding: 'determine',
        tijiaozhong: 'Submitting',
        yijujue: 'Rejected',
        yitongguo: 'Passed',
        huoqutuxingyanzhengmashibaiqingchongxinqingqiu: 'Failed to obtain verification code, please re request',
        huoquyouxiangyanzhengmashibai: 'Failed to obtain email verification code,',
        qingxianwanchengyouxiangrenzheng: 'Please complete email authentication first',
        huoquyouxiangshibaiqingchongxinqingqiu: 'Failed to obtain email, please re request',
        youxiangyanzhengmacuowu: 'The email verification code is incorrect,',
    },
    contractTransactionRecord: {
        fanhui: 'Back',
        zanwushuju: 'No data',
        heyuedingdan: 'Contract order',
        qingxuanze: 'Please choose',
        quanbu: 'whole',
        kaiduo: 'open long',
        pingkong: 'close short',
        kaikong: 'open short',
        pingduo: 'close long',
        xianjiaweituo: 'limit order',
        shijiaweituo: 'market order',
        suoyouzhuangtai: 'All States',
        tijiaoshibai: 'Submission failed',
        zhunbeitijiao: 'Ready to submit',
        yitijiao: 'Submitted',
        bufentijiao: 'Partial transaction',
        bufentijiaoyichedan: 'Partial cancellation',
        quanbuchengjiao: 'All transactions',
        yichedan: 'Cancelled',
        huoqushibai: 'Acquisition failed',
        heyuedaima: 'contract',
        ganggan: 'lever',
        xiadanshijian: 'order time',
        chengjiaoshijian: 'transaction time',
        fangxiang: 'direction',
        baojialeixing: 'quotation type',
        weituoliang: 'entrusted quantity',
        weituojia: 'commission price(USDT)',
        chengjiaoliang: 'turnover',
        chengjiaojunjia: 'average price',
        yingkui: 'P/L(USDT)',
        shouxufei: 'fee(USDT)',
        zhuangtai: 'state',
        gupiaodingdan: 'Stock order',
        waihuidingdan: 'FX order',
        gupiaodaima: 'Stock',
        waihuidaima: 'FX',
    },
    kLine:{
        zhibiao:'Indicator',
        shezhi:'Set up',
        quanping:'Full screen',
        tuichuquanping:'Exit',
        jishuzhibiao:'Indicator',
        futuzhibiao:'Sub graph indicators',
        zhutuzhibiao:'Main image indicators',
        huifumoren:'Restores defaul',
        lazhutuleixing:'Candlestick chart type',
        qingxuanze:'Please select',
        quanshixin:'All solid',
        quankongxin:'Fully hollow',
        zhangkongxin:'All Hollow',
        diekongxin:'Falling hollow',
        mianjitu:'Area',
        jiagezhouleixing:'Price axis type',
        xianxingzhou:'Line axis',
        baifenbizhou:'Percentage axis',
        duishuzhou:'Number axis',
        zuigaojiaxianshi:'Display high price',
        zuidijiaxianshi:' Display lowest price',
        zuixinjiaxianshi:'Display latest price',
        zhibiaozuixinzhixianshi:'Display indicator latest price',
        daozhizuobiao:"Inverted coordinates",
        wanggexianxianshi:'display grid lines',
    },
    SecurityCenter: {
        fanhui: 'Back',
        anquanzhongxin: 'Security Center',
        qingshurunicheng: 'Please enter a nickname',
        nichengxiugaishibai: 'Nickname modification failed',
        huoquyonghuxinxishibai: 'Failed to obtain user information',
        xiugaidenglumima: 'Modify the login password',
        huoqutuxingyanzhengmashibaiqingchongxinqingqiu: 'Failed to get verification code,Please request again',
        qingshurujiumima: 'Please enter your old password',
        qingshuruxinmima: 'Please enter new password',
        qingzaicishuruxinmima: 'Please enter new password again',
        qingshurutuxingyanzhengma: 'Please enter graphical verification code',
        qingshuruyanzhengma: 'Please enter the verification code',
        huoquzhuceshoujihaoshibai: 'Failed to obtain a registered mobile phone number',
        qingxianwanchengshoujirenzheng: 'Please finish phone certification',
        huoqushoujiyanzhengmashibai: 'Failed to get the verification code on your phone',
        huoqushoujiyanzhengma: 'Get verification code',
        houchongxinfasong: 's After resending',
        mimacuowu: 'Wrong password',
        liangcixinmimashurubuyizhiqingjiancha: 'Two new password is inconsistent,Please check',
        xinmimaheyuanmimabunengyiyang: 'The old and new passwords are not the same',
        qingshurushoujiyanzhengma: 'Please enter the verification code on your mobile phone',
        yanzhengmacuowu: 'verification code incorrect',
        denglumimaxiugaichenggong: 'The login password has been changed',
        denglumimaxiugaishibai: 'Failed to change the login password',
        xiugaizijinmima: 'Modify the money password',
        qingshuruzijinmima: 'Please enter your fund password',
        qingzaicishuruzijinmima: 'Please enter the fund password again',
        liangcizijinmimabuyizhiqingjiancha: 'Two fund password is inconsistent,Please check',
        shezhizijinmimachenggong: 'The fund password was set successfully',
        shezhizijinmimashibai: 'Failed to set a fund password',
        xiugaizijinmimachenggong: 'The fund password was changed successfully',
        xiugaizijinmimashibai: 'Failed to change the fund password',
        youxiangrenzheng: 'Email authentication',
        huoquyouxiangyanzhengma: 'Obtain an email verification code',
		qingshuruyouxiangdizhi: 'Please enter the email address',
        huoquyouxiangyanzhengmashibai: 'Failed to obtain the email verification code',
        qingshuruyouxiangyanzhengma: 'Please enter the email verification code',
        youxiangyanzhengchenggong: 'The email address was verified successfully',
        weirenzheng: 'unauthorized',
        queding: 'OK',
        anquanshezhiguanli: 'Security management',
        denglumima: 'password',
        tongguoshezhidenglumimaninkeyishiyongzhanghaomimazhijiedenglu: 'By setting the password,You will be able to use the account password login directly',
        xiugai: 'Modify',
        zijinmima: 'money password',
        yongyubaohuzijinanquan: 'Used to protect the security of the money',
        shezhi: 'Setup',
        yongyudenglutibizhaohuimimaxiugaianquanshezhishijinxinganquanyanzheng: 'For login、Mention Coin、Retrieve password、Modify the security Settings for security verification',
        yirenzheng: 'certified',
        renzheng: 'certification',
        shimingrenzheng: 'Real-name authentication',
        yongyumaibimaibitibichongbihuazhuanshijinxinganquanrenzheng: 'Buy Coin for Basics currency、Selling Coin、Mention Coin、Charge Coin、When transfer for safety certification',
        daishenhe: 'Audit',
        yibohui: 'Has been rejected',
        yuandenglumima: 'The original password',
        xinmima: 'New password',
        quedingxinmima: 'Make sure new password',
        tuxingyanzhengma: 'Graphical verification code',
        yanzhengma: 'Verification code',
        zijinmima: 'Money password',
        querenzijinmima: 'Confirm money password',
        youxiangdizhi: 'Email address',
        qingxianwanchengyouxiangrenzheng: 'Please complete email authentication first',
        xiugaitouxiangchenggong: 'Successfully modified avatar',
        xiugaitouxiangshibai: 'Failed to modify avatar',
        shangchuantouxiangzhinengshijpggeshi: 'Uploading avatar images can only be in JPG format!',
        shangchuantouxiangtupiandaxiaobunengchaoguo2M: 'The size of the uploaded avatar image cannot exceed 2MB!',
    },
    verification: {
        fanhui: 'Back',
        shimingrenzheng: 'Real-name authentication',
        qingshuruxingming: 'Please enter your name',
        qingshuruzhengjianhaoma: 'Please enter your ID number',
        huoquyonghuxinxishibai: 'Failed to obtain user information',
        wenjiangeshicuowu: 'File format error!',
        wenjianbunengdayu5M: 'File cannot be greater than 5M',
        qingtianxiexingming: 'Please enter name',
        qingtianxiezhengjianhaoma: 'Please enter ID number',
        qingshangchuanzhengjianzhengmian: 'Please Upload positive of your ID card',
        qingshangchuanzhengjianfanmian: 'Please Upload the other side of your ID card',
        tijiaochenggong: 'Submitted successfully',
        kaiqishenfenrenzheng: 'Turn on identity authentication',
        bohuiyuanyin: 'Reasons for rejection',
        qingtianxiejiashizhizhaogongminkahuzhao: 'Please fill in the ID card information',
        xingming: 'full name',
        zhengjianhaoma: 'ID card No',
        qingshangchuanjiashizhizhaogongminkahuzhao: 'Please upload ID card information',
        shangchuanzhengjianzhengmian: 'Upload positive',
        huotuozhuafangru: 'Or drag and drop into',
        shangchuanzhengjianfanmian: 'Upload the other side',
        tijiao: 'Submit',
    },
    invite: {
        yaoqinghaoyoudejiangli: 'Rewards for inviting friends',
        yongjiukede: 'Permanently available',
        jiaoyifanyong: 'Transaction rebate',
        fasongyaoqinggeihaoyou: 'Send invitations to friends',
        tongguofenxianglianjieyaoqinghaoyouzhuce: 'Invite friends to register through the sharing link sgcex',
        haoyouzhuce: 'Friend registration',
        haoyoujieshouyaoingwanchengzhucebingjinxingjiaoyi: 'Friends accept invitation, complete registration and trade',
        huodexiangyingbilijiangli: 'Obtain corresponding proportion of reward',
        qingsonghuodejiaoyishouxufeijianglifuli: 'Easy access to transaction fee reward benefits',
        wodezhuanshufenxiangfangshi: 'My own way to share',
        yaoqinglianjie: 'Invitation link',
        jiazaizhong: 'Loading...',
        fuzhilianjie: 'Copy link',
        yaoqingma: 'Invitation code',
        fuzhiyaoqingma: 'Copy invitation code',
        wodeyaoqing: 'My invitation',
        yaoqingrenshu: 'Number of invitees',
        huodejiangli: 'Get rewards',
        huodongxize: 'Activity rules',
        tips1: '1、After friends accept the invitation, a corresponding proportion of rewards will be generated for each real transaction handling fee.',
        tips2: '2、The reward is distributed to your contract trading account in the form of usdt, and the proportion of usdt reward is',
        tips201: '.',
        tips3: '3、Real time settlement and arrival of trading rewards for friends; Reward amount usdt = actual transaction volume * Commission proportion * reward proportion',
        tips4: '4、The invitee can enjoy the rebate reward of friend transaction, which is valid for a long time.',
        tips5: '5、The handling fee for charging and withdrawing currency will not participate in the handling fee reward.',
        tips6: '6、For in-depth channel cooperation intention, please contact globalcoinone@gmail.com, including account number, exhibition country and region, self owned resource background, brief exhibition plan, self introduction, personal contact information, etc.',
        tips7: 'In case of any adjustment of activities, the sgcex platform update shall prevail, and the final interpretation right shall belong to sgcex.',
    },
    register: {
        huoqutuxingyanzhengmashibaiqingchongxinqingqiu: 'Failed to get verification code,Please request again',
        qingshuruyouxiang: 'Please enter your email',
        qingshurutuxingyanzhengma: 'Please enter graphical verification code',
        qingshuruyanzhengma: 'Please enter verification code',
        qingshurunindemima: 'Please enter your password',
        qingzaicishurunindemima: 'Please enter your password again',
        qingshuruzhifumima: 'Please enter the payment password',
        xuantian: 'Please enter Invite code',
        yonghuzhuce: 'User registration',
        huanyingdenglu: 'Welcome login',
        guanfangwangzhan: 'official website',
        xiangmumingcheng: 'SGCEX',
        youxiang: 'Email',
        tuxingyanzhengma: 'Graphical verification code',
        yanzhengma: 'Verification code',
        huoquyanzhengma: 'Get verification code',
        shezhimima: 'Set the password',
        quedingmima: 'Determine password',
        zhifumima: 'Payment password',
        yaoqingma: 'Invite code',
        zhuce: 'registered',
        yiyouzhanghao: 'Existing account?Go',
        denglu: 'Login',
        qingshuruyouxiangdizhi: 'Please enter the email address',
        huoqushibai: 'Acquisition failed',
        houchongxinfasong: 's After resending',
        liangcishurudemimabuyizhi: 'The password entered twice is inconsistent',
        zhucechenggong: 'Registration is successful',
        zhuceshibai: 'Registration failed',
    },
    treasure:{
        jichubizhong: 'Base Coin',
        shouyibizhong: 'Profit Coin',
        wakuangtianshu: 'Day Rebate',
        shouyitianshu: 'Rebate Day',
        suodingtianshu: 'Lock Day',
        yichanshengshouyi: 'Rebate Amount',
        yuqishouyi: 'Amount',
        kaishishijian: 'Add Time',
        jieshushijian: 'Release Time',
        qingshuruninyaosuodingdebizhongshuliang: 'please type you want to lock USDT quantity',
        huoqushibai: 'Acquisition failed',
        meiyitian: 'per 1 day',
        dakai: 'Open',
        yuanyuzhoukuangchi: 'Metaverse Mining Pool',
        jilu: 'Record',
        kuangchixiangqing: 'Mine Details',
        suo: 'Lock',
        yitianchanchu: '1 day produce',
        zuixiaosuodingshijian: 'Min lock time',
        nindeUSDT: 'Your USDT',
        tian: 'Day',
        quanbu: 'All',
        suoUSDTbingkaishishengchan: 'Lock USDT and begin produce',
        ninquedingyaosuoUSDTkaishishengchanma: 'Are you sure Lock USDT and begin produce?',
        tishi: 'Tips',
        queding: 'OK',
        quxiao: 'Cancel',
        kaishishengchan: 'begin produce!',
        yiquxiao: 'Cancelled',
    },
    subscription:{  
        rengouliebiao:'Subscription List',
        jijiangdaolai:'Upcoming',
        jinxingzhong:'In progress',
        quanbu:'Whole',
        keshiyonghuobi:"Can use currency",
        zongshuliang:'Total',
        meiyouxianzhi:'No restrictions',
        shengyushuliang:'Remaining Quantity',
        kaishishijian:'Time-on',
        zhuangtai:'State',
        jilu:'Record',
        zanwujilu:"No records",
        leixing:'Type',
        shuliang:'Quantity',
        goumaijine:'Purchase amount',
        shijian:'Time',
        goumai:'Purchase',
        duichu:'Exchange out',
        jiangli:'Reward',
        tibi:'Withdrawal',
        huidaomulu:'Return',
        dangqianchiyou:'Currently owned',
        keyong:'Available',
        keduihuan:'Convertible',
        shishihangqing:'Real-Time Quotes',
        yue:'Balance',
        biaoti:'Title',
        fashoushuliang:'Sales quantity',
        jindu:"Schedule",
        goumaishuliang:'Purchase quantity',
        ge:'One',
        woyiyuedu:'I have read',
        daibigoumaixieyi:'Purchase agreement',
        qingshurugoumaijine:'Please enter the purchase amount',
        shifoyaogoumai:'Do you want to purchase',
        tishi:'Tips',
        queding:'Determine',
        quxiao:'Cancel',   
    },
  }
import ctsTrade from '@/utils/cts_msg_pb.js'
import { baseMethods } from '@/utils/methods.js'
import store from '@/store'
import { teadeWSReq } from '@/utils/packedTrade'
import { EncodePack } from '@/utils/ByteArray.js';
import context from '../main'
import i18n from '@/i18n' 
import router from '../router'

function LeverRateByContractcode(Contractcode) {
    var obj = store.state.leverList.find(item => item.code === Contractcode) ;
    if (obj)
    {
        return obj.leverate; 
    } else {
        return '20';
    }
};

function getContractinfo(Contractcode) {
    var obj = store.state.contractList.find(item => item.getContractcode() === Contractcode) ;
    return obj;
};

function calcqpPrice(Contractcode, Costopen, Volume, Direction) {
    var wave = 0;
    var size = 0;
    var obj = getContractinfo(Contractcode) ;
    if (obj)
    {
        wave = obj.getPricetick().toString().split('.')[1].length;
        size = obj.getContractsize();

        var activepfex = LeverRateByContractcode(Contractcode);
        var marginBill = baseMethods.accDiv(baseMethods.accMul(baseMethods.accMul(Costopen, Volume), size), activepfex);
        var fee = baseMethods.accMul(baseMethods.accMul(Costopen, Volume), store.state.tradeFee.open);
        //如果需要更精确余额加上浮动盈亏
        var qpsfdyk = baseMethods.accSub(baseMethods.accSub(store.state.userWallet.balance, fee), baseMethods.accDiv(marginBill, 2));
        var qpksds = baseMethods.accDiv(baseMethods.accDiv(qpsfdyk,Volume), size);
        if (Direction == 0) {
            var qpprice = baseMethods.accSub(Costopen, Math.abs(qpksds));
        } else {
            var qpprice = baseMethods.accAdd(Costopen, Math.abs(qpksds));
        }
        return qpprice;
    } else {
        return 0;
    }
}


function flashHoldOrderList() {
    store.state.holdOrderList = [];
    store.state.holdList.forEach((item) => {
        var obj= {};
        obj.id = item.getId();
        obj.strategyID = item.getStrategyid();
        obj.traderUserID = item.getTraderuserid();
        obj.Contractcode = item.getContractcode();
        obj.Costopen = item.getCostopen();
        obj.positionMargin = item.getPositionmargin();
        obj.Profit = item.getProfit();
        obj.Leverrate = LeverRateByContractcode(item.getContractcode());
        obj.volume = item.getVolume();
        obj.available = item.getAvailable();
        obj.direction = item.getDirection();
        obj.profitPrice = item.getProfitprice();
        obj.lossPrice = item.getLossprice();
        obj.qpPrice = calcqpPrice(item.getContractcode(), item.getCostopen(), item.getVolume(), item.getDirection());
        var Contractinfo = getContractinfo(item.getContractcode());
        if (Contractinfo) {
            obj.wave = Contractinfo.getPricetick().toString().split('.')[1].length;
            obj.hysize = Contractinfo.getContractsize();
            obj.pricetick = Contractinfo.getPricetick();
            obj.fee = baseMethods.accMul(baseMethods.accMul(baseMethods.accMul(obj.Costopen, obj.volume), obj.hysize), store.state.tradeFee.open);
        } else {
            obj.wave = 0;
            obj.hysize = 0;
            obj.pricetick = 0;
            obj.fee = 0;
        }

        store.state.holdOrderList.push(obj);
    });
};

function pushEntrustOrderList(item) {
    var obj= {};
    obj.id = item.getId();
    obj.symbol = item.getSymbol();
    obj.Contractcode = item.getContractcode();
    obj.volume = item.getVolume();
    obj.Price = item.getPrice();
    obj.direction = item.getDirection();
    obj.offset = item.getOffset();
    obj.Status = item.getStatus();
    obj.Levelrate = item.getLevelrate();
    obj.Tradevolume = item.getTradevolume();
    obj.Fee = item.getFee();
    obj.Tradeavgprice = item.getTradeavgprice();
    obj.Profit = item.getProfit();
    obj.Tradedate = item.getTradedate();
    var status = '';
    if (item.getStatus() == 0) {
        status = i18n.t("trade")['weituotijiaoshibai'];
    } else if (item.getStatus() == 1) {
        status = i18n.t("trade")['weituozhilingtijiao'];
    } else if (item.getStatus() == 2) {
        status = i18n.t("trade")['weituoyitijiao']; //可撤单操作
    } else if (item.getStatus() == 3) {
        status = i18n.t("trade")['weituobufenchengjiao'];
    } else if (item.getStatus() == 4) {
        status = i18n.t("trade")['weituobufenchengjiaoyichedan'];
    } else if (item.getStatus() == 5) {
        status = i18n.t("trade")['weituowanquanchengjiao'];
    } else if (item.getStatus() == 6) {
        status = i18n.t("trade")['weituowanquanchedan'];
    } else if (item.getStatus() == 11) {
        status = i18n.t("trade")['weituochedanzhong'];
    }
    obj.StatusText = status;
    var DirectionOffset = '';
    if (item.getDirection() == 0) {
        if (item.getOffset() == 0) {
            DirectionOffset = i18n.t("trade")['weituokaiduo'];
        } else if (item.getOffset() == 1) {
            DirectionOffset = i18n.t("trade")['weituopingkong'];
        }
    } else if (item.getDirection() == 1) {
        if (item.getOffset() == 0) {
            DirectionOffset = i18n.t("trade")['weituokaikong'];
        } else if (item.getOffset() == 1) {
            DirectionOffset = i18n.t("trade")['weituopingduo'];
        }
    }
    obj.DirectionText = DirectionOffset;
    var Contractinfo = getContractinfo(item.getContractcode());
    if (Contractinfo) {
        obj.wave = Contractinfo.getPricetick().toString().split('.')[1].length;
    } else {
        obj.wave = 0;
    }

    store.state.EntrustorderList.push(obj);
};
function flashEntrustOrderList() {
    store.state.EntrustorderList = [];
    store.state.EntrustList.forEach((item) => {
        //先插入Status == 2的元素
        //再插入Status != 2的元素
        if (item.getStatus() === 2) {
            pushEntrustOrderList(item);
        }
    });

    store.state.EntrustList.forEach((item) => {
        //先插入Status == 2的元素
        //再插入Status != 2的元素
        if (item.getStatus() !== 2) {
            pushEntrustOrderList(item);
        }
    });
};

function flashDealOrderList() {
    store.state.DealOrderList = [];
    store.state.DealList.forEach((item) => {
        var obj= {};
        obj.id = item.getId();
        obj.symbol = item.getSymbol();
        obj.Contractcode = item.getContractcode();
        obj.Tradevolume = item.getTradevolume();
        obj.Tradeprice = item.getTradeprice();
        obj.Createdate = item.getCreatedate();
        obj.direction = item.getDirection();
        obj.offset = item.getOffset();
        obj.Tradefee = item.getTradefee();
        obj.Offsetprofitloss = item.getOffsetprofitloss();
        var DirectionOffset = '';   
        if (item.getDirection() == 0) {
            if (item.getOffset() == 0) {
                DirectionOffset = i18n.t("trade")['chengjiaokaiduo'];
            } else if (item.getOffset() == 1) {
                DirectionOffset = i18n.t("trade")['chengjiaopingkong'];
            }
        } else if (item.getDirection() == 1) {
            if (item.getOffset() == 0) {
                DirectionOffset = i18n.t("trade")['chengjiaokaikong'];
            } else if (item.getOffset() == 1) {
                DirectionOffset = i18n.t("trade")['chengjiaopingduo'];
            }
        }
        obj.DirectionText = DirectionOffset;
        var Contractinfo = getContractinfo(item.getContractcode());
        if (Contractinfo) {
            obj.wave = Contractinfo.getPricetick().toString().split('.')[1].length;
        } else {
            obj.wave = 0;
        }

        store.state.DealOrderList.push(obj);
    });
};

function pushConditionOrderList(item) {
    var obj= {};
    obj.id = item.getId();
    obj.symbol = item.getSymbol();
    obj.Contractcode = item.getContractcode();
    obj.volume = item.getVolume();
    obj.direction = item.getDirection();
    obj.offset = item.getOffset();
    obj.type = item.getType();
    obj.priceType = item.getPricetype();
    obj.compare = item.getCompare();
    obj.Endprice = item.getEndprice();
    obj.Endtime = item.getEndtime();
    obj.Status = item.getStatus();
    obj.Createtime = item.getCreatetime();
    var DirectionOffset = '';   
    if (item.getDirection() == 0) {
        if (item.getOffset() == 0) {
            DirectionOffset = i18n.t("trade")['tiaojiankaiduo'];
        } else if (item.getOffset() == 1) {
            DirectionOffset = i18n.t("trade")['tiaojianpingkong'];
        }
    } else if (item.getDirection() == 1) {
        if (item.getOffset() == 0) {
            DirectionOffset = i18n.t("trade")['tiaojiankaikong'];
        } else if (item.getOffset() == 1) {
            DirectionOffset = i18n.t("trade")['tiaojianpingduo'];
        }
    }
    obj.DirectionText = DirectionOffset;
    var type = item.getType() == 0 ? i18n.t("trade")['tiaojianjiagetiaojian'] : i18n.t("trade")['tiaojianshijiantiaojian'];
    obj.typetext = type;
    var priceType = item.getPricetype() == 0 ? i18n.t("trade")['tiaojianzuixinjia'] : (item.getPricetype() == 1 ? i18n.t("trade")['tiaojianByijia'] : i18n.t("trade")['tiaojianSyijia']);
    obj.priceTypetext = priceType;
    var compare = item.getCompare() == 0 ? '>=' : '<=';
    obj.compartext = compare;
    var endpricetime = '';
    if (item.getType() == 0) {
        var endpricetime = item.getEndprice();
    } else {
        var endpricetime = item.getEndtime();
    };
    obj.endpricetime = endpricetime;
    var stutus = '';
    if (item.getStatus() == 0) {
        stutus = i18n.t("trade")['tiaojianzanting'];
    } else if (item.getStatus() == 1) {
        stutus = i18n.t("trade")['tiaojianyunxing'];
    } else if (item.getStatus() == 2) {
        stutus = i18n.t("trade")['tiaojianyiwancheng'];
    } else if (item.getStatus() == 3) {
        stutus = i18n.t("trade")['tiaojianshibai'];
    } else if (item.getStatus() == 4) {
        stutus = i18n.t("trade")['tiaojianyishanchu'];
    } else if (item.getStatus() == 5) {
        stutus = i18n.t("trade")['tiaojianshoudongchufawancheng'];
    } else if (item.getStatus() == 6) {
        stutus = i18n.t("trade")['tiaojianshoudongchufashibai'];
    };
    obj.statetext = stutus;

    store.state.ConditionOrderList.push(obj);
};

function flashConditionOrderList() {
    store.state.ConditionOrderList = [];
    store.state.ConditionList.forEach((item) => {
        if (item.getStatus() === 0 || item.getStatus() === 1) {
            pushConditionOrderList(item);
        }
    });

    store.state.ConditionList.forEach((item) => {
        if (item.getStatus() !== 0 && item.getStatus() !== 1) {
            pushConditionOrderList(item);
        }
    });
};


const teadeWSparse = { 
    parseData(decPack) {
        // console.log('msgType:', decPack.msgType);
        switch (decPack.msgType) {
            case 11001:
                var data_msg = baseMethods.decrypt(decPack.data);
                var res = proto.cts.TS2CLoginRes.deserializeBinary(data_msg);
                if (res.getResultcode() == 0) {
                    teadeWSReq.getLever();
                    // console.log('getToken:', res.getToken());
                    sessionStorage.setItem('tk', res.getToken());
                    // console.log('sessionStorage getToken:', sessionStorage.getItem('tk'));
                    var loginBackRes = res.getUser();
                    sessionStorage.setItem('uid', loginBackRes.getId());
                    var all_deposit = baseMethods.accAdd(loginBackRes.getMarginposition(), loginBackRes.getMarginfrozen());   //持仓保证金 - 冻结保证金
                    var canuseMoney = baseMethods.accSub(loginBackRes.getBalance(), all_deposit);     //余额 - 持仓保证金 - 冻结保证金
                    store.state.userWallet.balance = loginBackRes.getBalance();
                    store.state.userWallet.marginBalance = loginBackRes.getMarginbalance();
                    store.state.userWallet.marginPosition = loginBackRes.getMarginposition();
                    store.state.userWallet.marginFrozen = loginBackRes.getMarginfrozen();
                    store.state.userWallet.marginAvailable = loginBackRes.getMarginavailable();
                    store.state.userWallet.profitReal= loginBackRes.getProfitreal();                //已实现盈亏
                    store.state.userWallet.profitUnreal= loginBackRes.getProfitunreal();            //未实现盈亏
                    store.state.userWallet.riskRate= loginBackRes.getRiskrate();                    //保证金率
                    store.state.userWallet.followBalance = loginBackRes.getFollowbalance();
                    store.state.userWallet.canuseMoney = canuseMoney;
                    store.state.userWallet.userType = res.getUsertype()
                    store.state.userWallet.calcprofit = loginBackRes.getProfitunreal();

                    store.state.tradeFee.open = res.getOpenfeerate();
                    store.state.tradeFee.close = res.getClosefeerate();
                    store.state.loginStatus = true;
                    //获取法币资金
                    // this.getMyLegalCurrencyAccountInfo();
                    store.dispatch('getMyLegalCurrencyAccountInfo');
                    
                    let message = {
                        loginStatus: true,
                    }
                    context.$root.$emit('tradelogin', message);
                
                    this.heartCheck();
                } else {
                    // uni.hideLoading();
                    // let lang = uni.getStorageSync('lang');
                    // if(lang == 'en'){
                    //     uni.showToast({
                    //         title: 'Incorrect account or password',
                    //         icon:'none',
                    //         duration: 2000
                    //     });
                        
                    // }else{
                    //     uni.showToast({
                    //         title: '账号或密码不正确',
                    //         icon:'none',
                    //         duration: 2000
                    //     });
                    // }
                    
                    store.state.username = '';
                    store.state.userpass = '';
                    let message = {
                        loginStatus: false,
                    }
                    context.$root.$emit('tradelogin', message);
                    store.dispatch('tradeWS/close');
                }
                break;
            case 11002:
                var data_msg = baseMethods.decrypt(decPack.data);
                var res = proto.cts.TS2CModifyPwdRes.deserializeBinary(data_msg);
                var resultCode = res.getResultcode();
                context.$root.$emit('TS2CModifyPwdRes', resultCode);
                break;
            case 11003:
                // console.log('收到心跳包');    
                // store.state.tradeWSHart = 0;
                store.commit('tradeWS/ZERO_HARTCOUNT');
                break;
            case 11004:
                //被踢下线
                let message = {
                    loginStatus: false,
                }
                context.$root.$emit('tradelogin', message);
                store.dispatch('tradeWS/close');
                router.push({
                    path:'/LoginLayout'
                });
                break;
            case 11005:
                var data_msg = baseMethods.decrypt(decPack.data);
                var res = proto.cts.TS2CUpdateUserInfoRes.deserializeBinary(data_msg);
                var list = res.getUser();
                var balanceMoney = list.getBalance();
                var deposit_hold = list.getMarginfrozen();
                var frozen_deposi = list.getMarginfrozen();
                store.state.userWallet.marginBalance = list.getMarginbalance();
                store.state.userWallet.balance = list.getBalance();
                store.state.userWallet.followBalance = list.getFollowbalance();
                store.state.userWallet.marginPosition = list.getMarginposition();
                store.state.userWallet.marginFrozen = list.getMarginfrozen();
                // var all_deposit = baseMethods.accAdd(deposit_hold, frozen_deposi);
                // store.state.userWallet.canuseMoney = baseMethods.accSub(balanceMoney, all_deposit);
                store.state.userWallet.marginAvailable = list.getMarginavailable();//可用保证金
                break;
            case 11007:
                teadeWSReq.getHoldOrder();
                teadeWSReq.getEntrust();
                teadeWSReq.getOrderList();
                teadeWSReq.getConditionList();
                teadeWSReq.gethypzList();
                var data_msg = baseMethods.decrypt(decPack.data);
                var res = proto.cts.TS2CLeverRateListRes.deserializeBinary(data_msg);
                var list = res.getLeverrateList();
                
                list.forEach(item => {
                    let obj = {
                        code:item.getContractcode(),
                        leverate:item.getLeverrate()
                    }
                    store.state.leverList.push(obj)
                });
   
                context.$root.$emit('TS2CLeverRateListRes', '');
                break;
            case 11008:     //持仓单
                var data_msg = baseMethods.decrypt(decPack.data);
				var res = proto.cts.TS2CHoldOrderListRes.deserializeBinary(data_msg);
				var list = res.getOrderList();
				store.state.holdList = list;
                flashHoldOrderList();
                break;
            case 11009:     //委托单
                var data_msg = baseMethods.decrypt(decPack.data);
                var res = proto.cts.TS2CEntrustOrderListRes.deserializeBinary(data_msg);
                var list = res.getOrderList();
                store.state.EntrustList = list;
                flashEntrustOrderList();
                break;
            case 11010:     //成交单
                var data_msg = baseMethods.decrypt(decPack.data);
                var res = proto.cts.TS2CDealOrderListRes.deserializeBinary(data_msg);
                var list = res.getOrderList();
                store.state.DealList = list;
                flashDealOrderList();
                break;
            case 11011:
                var data_msg = baseMethods.decrypt(decPack.data);
                var res = proto.cts.TS2CAutoProfitLossListRes.deserializeBinary(data_msg);
                var list = res.getInfoList();
                var obj = {};
                list.forEach(item => {
                    obj = store.state.AutoProfitLossList.find(items => items.contractCode === item.getContractcode()) ;
                    if (obj) {
                        obj.profit = item.getProfit();
                        obj.loss = item.getLoss();  
                    }
                });
                break;
            case 11012:
                var data_msg = baseMethods.decrypt(decPack.data);
                var res = proto.cts.TS2CSetAutoProfitLossListRes.deserializeBinary(data_msg);
                var list = res.getInfoList();
                var obj = {};
                list.forEach(item => {
                    obj = store.state.AutoProfitLossList.find(items => items.contractCode === item.getContractcode()) ;
                    if (obj) {
                        obj.profit = item.getProfit();
                        obj.loss = item.getLoss();  
                    }
                });
                var SetAutoProfitLossListRes = {
                    describe: baseMethods.Utf8ArrayToStr(res.getDescribe()),
                }
                context.$root.$emit('TS2CSetAutoProfitLossListRes', SetAutoProfitLossListRes);
                break;
            case 11013:
                var data_msg = baseMethods.decrypt(decPack.data);
                var res = proto.cts.TS2CTransferCapitalRes.deserializeBinary(data_msg);
                var TransferCapitalRes = {
                    Resultcode: res.getResultcode(),
                    describe: baseMethods.Utf8ArrayToStr(res.getDescribe()),
                }
                context.$root.$emit('TS2CTransferCapitalRes', TransferCapitalRes);
                break;
            case 11050:     //开仓返回
                var data_msg = baseMethods.decrypt(decPack.data);
                var res = proto.cts.TS2COpenRes.deserializeBinary(data_msg);
                var OpenRes = {
                    Resultcode: res.getResultcode(),
                    describe: baseMethods.Utf8ArrayToStr(res.getDescribe()),
                }
                context.$root.$emit('TS2COpenRes', OpenRes);
                break;
            case 11051:     //平仓返回
                var data_msg = baseMethods.decrypt(decPack.data);
                var res = proto.cts.TS2CCloseRes.deserializeBinary(data_msg);
                var CloseRes = {
                    Resultcode: res.getResultcode(),
                    describe: baseMethods.Utf8ArrayToStr(res.getDescribe()),
                }
                context.$root.$emit('TS2CCloseRes', CloseRes);
                break;
            case 11052:
                var data_msg = baseMethods.decrypt(decPack.data);
                var res = proto.cts.TS2CCancelRes.deserializeBinary(data_msg);
                var CancelRes = {
                    Resultcode: res.getResultcode(),
                    describe: baseMethods.Utf8ArrayToStr(res.getDescribe()),
                }
                context.$root.$emit('TS2CCancelRes', CancelRes);
                break;
            case 11053:     //添加委托单
                var data_msg = baseMethods.decrypt(decPack.data);
                var res = proto.cts.TS2CAddEntrustRes.deserializeBinary(data_msg);
                var list = res.getOrderList();
                list.forEach(items => {
                    store.state.EntrustList.push(items);
                });
                flashEntrustOrderList();
                break;
            case 11054:     //更新委托单
                var data_msg = baseMethods.decrypt(decPack.data);
                var res = proto.cts.TS2CUpdateEntrustRes.deserializeBinary(data_msg);
                var list = res.getOrderList();
                list.forEach(items => {
                    store.state.EntrustList.forEach((item, index) => {
                        if (item.getId() == items.getId()) {
                            store.state.EntrustList.splice(index, 1);
                            store.state.EntrustList.push(items);
                        }
                    })
                });
                flashEntrustOrderList();
                break;
            case 11055:
                var data_msg = baseMethods.decrypt(decPack.data);
                var res = proto.cts.TS2CAddHoldRes.deserializeBinary(data_msg);
                var list = res.getOrderList();
                list.forEach((item, index) => {
                    store.state.holdList.push(item);
                });
                flashHoldOrderList();
                break;
            case 11056:
                var data_msg = baseMethods.decrypt(decPack.data);
                var res = proto.cts.TS2CUpdateHoldRes.deserializeBinary(data_msg);
                var list = res.getOrderList();
                list.forEach(items => {
                    store.state.holdList.forEach((item, index) => {
                        if (item.getId() == items.getId()) {
                            store.state.holdList.splice(index, 1);
                            store.state.holdList.push(items);
                        }
                    })
                }) ;
                flashHoldOrderList();
                break;
            case 11057:
                var data_msg = baseMethods.decrypt(decPack.data);
                var res = proto.cts.TS2CDeleteHoldRes.deserializeBinary(data_msg);
                var list = res.getOrderidList();
                list.forEach(items => {
                    store.state.holdList.forEach((item, index) => {
                        if (item.getId() == items) {
                            store.state.holdList.splice(index, 1);
                        }
                    })
                });
                flashHoldOrderList();
                break;
            case 11058:
                var data_msg = baseMethods.decrypt(decPack.data);
                var res = proto.cts.TS2CAddDealRes.deserializeBinary(data_msg);
                var list = res.getOrderList();
                list.forEach(items => {
                    store.state.DealList.push(items);
                }); 
                flashDealOrderList();
                break;
            case 11059:     //修改杠杆倍率返回
                var data_msg = baseMethods.decrypt(decPack.data);
                var res = proto.cts.TS2CModifyLeverRateRes.deserializeBinary(data_msg);
                var obj = res.getLeverrate();
                let objcode = {
                    code:obj.getContractcode(),
                    leverate:obj.getLeverrate()
                }
                console.log(objcode)
                if (res.getResultcode() == 0) {
                    let mflag = store.state.leverList.some(item => item.code === objcode.code);
                    if(mflag){
                        store.state.leverList.forEach((item, index) => {
                            if (item.code == objcode.code) {
                                store.state.leverList.splice(index, 1,objcode);
                            }
                        });
                    }else{
                        store.state.leverList.push(objcode)
                    }
            
                    //杠杆修改成功
                    let message = {
                        ModifyRes: true,
                    }
                    context.$root.$emit('TS2CModifyLeverRateRes', message);
                } else {
                    let message = {
                        ModifyRes: false,
                    }
                    context.$root.$emit('TS2CModifyLeverRateRes', message);
                }
                
                break;
            case 11060:
                //未收到返回，需要查一下原因
                var data_msg = baseMethods.decrypt(decPack.data);
                var res = proto.cts.TS2CSetProfitLossRes.deserializeBinary(data_msg);
                var SetProfitLossRes = {
                    Resultcode: res.getResultcode(),
                    describe: baseMethods.Utf8ArrayToStr(res.getDescribe()),
                }
                context.$root.$emit('TS2CSetProfitLossRes', SetProfitLossRes);
                break;
            case 11080:
                var data_msg = baseMethods.decrypt(decPack.data);
                var res = proto.cts.TS2CConditionOrderListRes.deserializeBinary(data_msg);
                var list = res.getListList();
                store.state.ConditionList = list;
                flashConditionOrderList();
                break;
            case 11081:
                var data_msg = baseMethods.decrypt(decPack.data);
                var res = proto.cts.TS2CSetConditionOrderRes.deserializeBinary(data_msg);
                var ConditionOrderRes = {
                    Resultcode: res.getResultcode(),
                    describe: baseMethods.Utf8ArrayToStr(res.getDescribe()),
                }
                context.$root.$emit('TS2CSetConditionOrderRes', ConditionOrderRes);
                break;
            case 11082:
                var data_msg = baseMethods.decrypt(decPack.data);
                var res = proto.cts.TS2CAddConditionOrderRes.deserializeBinary(data_msg);
                var obj = res.getList();
                store.state.ConditionList.push(obj);
                flashConditionOrderList();
                break;
            case 11083:    
                var data_msg = baseMethods.decrypt(decPack.data);
                var res = proto.cts.TS2CUpdateConditionOrderRes.deserializeBinary(data_msg);
                var list = res.getListList();
                list.forEach(items => {
                    store.state.ConditionList.forEach((item, index) => {
                        if (item.getId() == items.getId()) {
                            store.state.ConditionList.splice(index, 1);
                            store.state.ConditionList.push(items);
                        }
                    })
                });
                flashConditionOrderList();
                break;
            default:
                console.log('unKnown type:' + decPack.msgType);
                break;
        }
    },
    heartCheck() {
		//心跳包
		var interval = setInterval(function(){
			//这里发送一个心跳，后端收到后，返回一个心跳消息，
			if (store.state.tradeWS.ws.readyState === WebSocket.OPEN) {
                // console.log('发送心跳包');
                var message = new proto.cts.C2TSHeartbeatReq();
                var bytes = message.serializeBinary();
                var bytes_encry = baseMethods.encrypt(bytes)
                var heart_note = EncodePack(bytes_encry,'10003');
                store.dispatch('tradeWS/sendMessage', heart_note);
                //心跳计数加1
                store.commit('tradeWS/INC_HARTCOUNT');
				if (store.state.tradeWS.wsHart > 3) {
					clearInterval(interval);
					// that.socketTask.close();
                    //关闭套接字
                    store.dispatch('tradeWS/close');
                    //心跳计数置0
                    store.commit('tradeWS/ZERO_HARTCOUNT');
                    //登录状态置为未登录
                    store.state.loginStatus = false;
				}
			} else {
                store.commit('tradeWS/INC_HARTCOUNT');
				if (store.state.tradeWSHart > 3) {
					clearInterval(interval);
					// that.socketTask.close();
                    store.dispatch('tradeWS/close');
					// store.state.tradeWSHart = 0;
                    store.commit('tradeWS/ZERO_HARTCOUNT');
                    // getApp().globalData.loginStatus = false;
                    store.state.loginStatus = false;
					
					// uni.reLaunch({
					// 	url:'/pages/index/index'
					// })
				}
            }
		}, 30000)
	},
};

export {
    teadeWSparse,
}
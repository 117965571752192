<template>
    <div class="showpart" >
        <div style="width: 1200px;">
            <div class="flex_row_left" style="height: 50px; background-color: #eee;">
                <el-page-header 
                style="margin-left: 10px;"
                @back="goBack" 
                :content="i18n('tibi')"
                :title="i18n('fanhui')">
                </el-page-header> 
            </div>

            <div style="border: 1px solid #E6ECF2;">
                <div class="flex_row_left kuang">
                    <div :class="ClassCoinUSDT()" @click="clickCoin('USDT')">USDT</div>
                    <!-- <div :class="ClassCoinUSDC()" @click="clickCoin('USDC')">USDC</div> -->
                </div>

                <div style="background-color: #fff;">
                    <div class="showcontent">
                        <div class="lname"> {{ i18n('lianmingcheng') }} </div>
                        <div class="flex_row_between">
                            <div :class="ClassTRC20()" @click="clickChain('TRC20')">TRC20</div>
                            <div :class="ClassERC20()" @click="clickChain('ERC20')">ERC20</div>
                            <div :class="ClassOMNI()" @click="clickChain('OMNI')">OMNI</div>
                        </div>
                        <div class="lname"> {{ i18n('tibidizhi') }} </div>
                        <el-input 
                        v-model="inputaddress" 
                        class="address" 
                        @input="changeAddress"
                        :placeholder="i18n('qingshurutibidizhi')"></el-input>
                        <div class="flex_row_left">
                            <div class="lname flex1"> {{ i18n('tibishuliang') }} </div>
                            <div class="lname flex1" style="margin-left: 22px;"> {{ i18n('shouxufei') }} </div>
                        </div>
                        <div class="flex_row_left">
                            <div class="flex1 flex_row_left" style="border: 1px solid #CCCCCC; height: 50px;">
                                <el-input-number v-model="tbnum" size="mini"
                                :precision="2" :step="1" :min="0" :max="userWallet.LegalCurrency" 
                                style="background-color: #fff; width: 60%; margin-left: 10px;" 
                                class="inputnum"
                                @change="changetbnum"
                                :placeholder="i18n('qingshurutibishuliang')"></el-input-number>
                                <div class="fontSize14 color_999 changeBZ" style="margin: 0 20px;">USDT</div>
                                <div class="all" @click="allClick()"> {{ i18n('quanbu') }} </div>
                            </div>

                            <div class="flex1 flex_row_left" style="border: 1px solid #CCCCCC; height: 50px; margin-left: 22px;">
                                <div style="margin-left: 10px; color: #999;">
                                    {{ fee | filter_moneyFormat(8) }}
                                </div>
                            </div>
                        </div>
                        <div class="kyye"> {{ i18n('keyong') }} {{ userWallet.LegalCurrency | filter_moneyFormat(8) }}
                            <i class="changeBZ">USDT</i>
                        </div>
                        <div class="dzsl"> {{ i18n('daozhangshuliang') }} 
                            <i class="changeBZ">USDT</i> 
                            <span class="dznum color_green"> {{ dznum | filter_moneyFormat(8) }} </span>
                        </div>
                        <div :class="{'drawbtn': true, 'noclick': !canClick}" @click="addOrder()"> {{i18n('tibi')}} </div>
                        <div class="tips color_999 fontSize12">
                            <div class="minnum"> {{ i18n('zuixiaotibishuliang') }} {{ minwithdraw }} 
                                <i class="changeBZ">USDT</i>
                            </div>
                            <div> {{ i18n('tishixinxi') }} </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="orderlist" style="border: 1px solid #E6ECF2; margin-top: 20px;">
                <el-table
                    :data="recordListData"
                    style="width: 100%;"
                    :empty-text="i18n('zanwushuju')"
                    :header-cell-style="{background:'#F9F9F8', padding: '0', height:'45px'}"
                    :row-style="{background:'#F9F9F8'}">
                    <el-table-column
                        :label="i18n('shijian')"
                        min-width="100">
                        <template slot-scope="scope">
                            <span style="color: #000;"> 
                                {{scope.row.createTime | timeFormat('ymdhms')}} </span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        :label="i18n('leixing')"
                        min-width="100">
                        <template slot-scope="scope">
                            <span style="color: #000;"> {{i18n('putongtibi')}} </span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        :label="i18n('shuliang')"
                        min-width="100">
                        <template slot-scope="scope">
                            <span style="color: #000;"> 
                                {{ scope.row.withdrawVolumes | filter_moneyFormat(8) }} {{ scope.row.currencyType }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        :label="i18n('zhuangtai')"
                        min-width="100">
                        <template slot-scope="scope">
                            <span style="color: #000;"> 
                                {{ scope.row.status }} </span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        :label="i18n('caozuo')"
                        min-width="100">
                        <template slot-scope="scope">
                            <el-button
                                size="mini"
                                type="primary"
                                style="background-color: #1772F8; border-color: #1772F8; color: #fff; height: 30px; width: 100px;"
                                @click="seedetail(scope.row)"> {{i18n('chakanxiangqing')}} </el-button>
                        </template>
                    </el-table-column>
                </el-table>

                <div class="block">
                    <el-pagination
                        background
                        hide-on-single-page
                        :page-size=page_size
                        :pager-count="11"
                        layout="prev, pager, next"
                        :current-page.sync=page
                        @current-change="handleCurrentChange"
                        class="flex_row_right"
                        :total=totalCount>
                    </el-pagination>
                </div>
            </div>
        </div>

        <el-dialog class="dialogdetail" :title="i18n('dialogtitle')" :visible.sync="dialogdetail" width="30%"> 
            <div class="flex_row_center">
                <div class="show-box">
                    <div class="color_333 fontSize25 text_center money"> {{ dialogvolue | filter_moneyFormat(2) }} USDT</div>
                    <div class="delist">
                        <div class="flex_row_between">
                            <div> {{i18n('leixing')}} </div>
                            <div> {{i18n('putongtibi')}} </div>
                        </div>
                        <div class="flex_row_between">
                            <div> {{i18n('zhuangtai')}} </div>
                            <div> {{ dialogstatus }} </div>
                        </div>
                        <div class="flex_row_between">
                            <div> {{i18n('tibidizhi')}} </div>
                            <div> {{ dialogaddress }} </div>
                        </div>
                        <div class="flex_row_between">
                            <div> {{i18n('shouxufei')}} </div>
                            <div> {{ dialogfee | filter_moneyFormat(2) }}USDT </div>
                        </div>
                        <div class="flex_row_between">
                            <div> {{i18n('shijian')}} </div>
                            <div> {{ dialogtime | timeFormat('ymdhms') }} </div>
                        </div>
                    </div>
                </div>
            </div>
        </el-dialog>     
        
        <el-dialog class="dialogwithdraw" 
        :title="i18n('dialogtitlewithdraw')" 
        :visible.sync="dialogwithdraw" 
        :close-on-click-modal=false
        :close-on-press-escape=false
        width="35%"> 
            <div class="flex_row_center">
                <div class="Change-frame">
                    <div class="Change-input">
                        {{ i18n('tuxingyanzhengma') }}
                        <div class="flex_row_left">
                            <el-input 
                            v-model="imgcode" 
                            style="margin-top: 10px; width: 300px;"
                            :placeholder="i18n('qingshurutuxingyanzhengma')"></el-input>
                            <img @click="getImgCode()" class="authimg" :src="imgsrc" alt="">
                        </div>
                    </div>
                    <div class="Change-input">
                        {{ i18n('youxiangyanzhengma') }}
                        <div class="flex_row_left">
                            <el-input 
                            v-model="numcode" 
                            style="margin-top: 10px; width: 300px;"
                            :placeholder="i18n('qingshuruyouxiangyanzhengma')"></el-input>
                            <div :class="{'getcode': true, 'noclick': !getcodecanClick}" @click="getcodeClick()"> {{ getcodeTitle }} </div>
                        </div>
                    </div>

                    <div class="flex_row_center">
                        <div class="but-determine sureBtn" @click="sureBtnClick()"> {{ i18n('queding') }} </div>
                    </div>
                </div>
            </div>
        </el-dialog>       
    </div>
</template>

<script>
import { moneyFormat } from '@/utils/filters.js'
import { baseMethods } from '@/utils/methods.js'

export default {
    data() {
        return {
            CoinSelect: 'USDT',
            chainSelect: 'TRC20',
            inputaddress: '',
            userWallet: this.$store.state.userWallet,
            tbnum: undefined,
            fee: 0,
            feeRate: 0,
            minwithdraw: 0,
            dznum: 0,
            erc20fee: 0,
            erc20min: 0,
            trc20fee: 0,
            trc20min: 0,
            usdcfee: 0,
            usdcmin: 0,
            canClick: false,
            recordListData: [],
            page: 1,
            page_size: 10,
            totalCount: 0,
            dialogdetail: false,
            dialogvolue: 0,
            dialogstatus: undefined,
            dialogaddress: undefined,
            dialogfee: 0,
            dialogtime: undefined,
            dialogwithdraw: false,
            imgsrc: undefined,
            imgcodeid: undefined,
            imgcode: undefined,
            numcode: undefined,
            emailcid: undefined,
            getcodecanClick: true,
            count: 0,
            countDowntimer: null,
            getcodeTitle: this.$t('withdraw')['huoquyouxiangyanzhengma'],
        };
    },
    mounted() {
        
        this.$root.$on('tradelogin', (message) => {
            if (message.loginStatus) {
                this.getaddInfo();
                this.getList();
                this.getImgCode();
            } 
        });

        //判断一下套接字的连接状态
        if (this.$store.state.loginStatus) {
            this.getaddInfo();
            this.getList();
            this.getImgCode();
        };
    },
    computed: {
        i18n() {
            return function(name) {
                // index找到对应页面的obj name是onj的key
                return this.$t("withdraw")[name];
            }
		},
    },
    filters: {
        filter_moneyFormat: function (x, n) {
            return moneyFormat(x, n);
        },
        timeFormat: function (time, type) {
            if (time && time > 0) {
                if (type === 'ymdhms') {
                    var needdate = new Date(time * 1000);
                } else {
                    var needdate = new Date(time);
                }
                var Y = needdate.getFullYear() + '.';
                var M = (needdate.getMonth() + 1 < 10 ? '0' + (needdate.getMonth() + 1) : needdate.getMonth() + 1) + '.';
                var D = needdate.getDate() < 10 ? '0' + needdate.getDate() : needdate.getDate();
                var h = (needdate.getHours() < 10 ? '0' + needdate.getHours() : needdate.getHours()) + ':';
                var m = (needdate.getMinutes() < 10 ? '0' + needdate.getMinutes() : needdate.getMinutes()) + ':';
                var s = (needdate.getSeconds() < 10 ? '0' + needdate.getSeconds() : needdate.getSeconds());
                //      年 月 日 时 分 秒
                if (type === 'ymdhms') {
                    return Y + M + D + ' ' + h + m + s;
                } else {
                    return h + m + s;
                }
            } else {
                return '--';
            }
        },
    },
    methods: {
        goBack() {
            this.$router.go(-1);
        },
        ClassCoinUSDT() {
            if (this.CoinSelect === 'USDT') {
                return ['CoinUSDT', 'onselect'];
            } else {
                return ['CoinUSDT'];
            } 
        },
        ClassCoinUSDC() {
            if (this.CoinSelect === 'USDC') {
                return ['CoinUSDC', 'onselect'];
            } else {
                return ['CoinUSDC'];
            }
        },
        clickCoin(Coin) {
            this.CoinSelect = Coin;
        },
        ClassTRC20() {
            if (this.chainSelect === 'TRC20') {
                return ['lbtn', 'llist', 'on'];
            } else {
                return ['lbtn', 'llist'];
            }
        },
        ClassERC20() {
            if (this.chainSelect === 'ERC20') {
                return ['lbtn', 'llist', 'on'];
            } else {
                return ['lbtn', 'llist'];
            }
        },
        ClassOMNI() {
            if (this.chainSelect === 'OMNI') {
                return ['lbtn', 'llist', 'on'];
            } else {
                return ['lbtn', 'llist'];
            }
        },
        clickChain(Chain) {
            if (Chain === 'ERC20') {
                this.feeRate = this.erc20fee;
                this.minwithdraw = this.erc20min;
            } else if (Chain === 'OMNI') {
                this.$message({
                    message: this.$t('withdraw')['OMNIweihuzhong'],
                    type: 'warning'
                });
                return;
            } else if (Chain === 'TRC20') {
                this.feeRate = this.trc20fee; 
                this.minwithdraw = this.trc20min;
            }
            this.chainSelect = Chain;
            this.tbnum = 0;
            this.fee = 0;
            this.dznum = 0;
            this.canClick = false;
        },
        allClick() {
            if (this.userWallet.LegalCurrency < 0) {
                this.tbnum = 0;
                this.fee = 0;
                this.canClick = false;
            } else if (this.userWallet.LegalCurrency <= this.minwithdraw && this.userWallet.LegalCurrency > 0) {
                this.tbnum = this.userWallet.LegalCurrency;
                this.calcfee();
                this.dznum = 0;
                this.canClick = false;
            } else if (this.userWallet.LegalCurrency > this.minwithdraw) {
                this.tbnum = this.userWallet.LegalCurrency;
                this.calcfee();
                this.dznum = this.userWallet.LegalCurrency - this.fee;
                if (this.inputaddress !== '') {
                    this.canClick = true;
                } else {
                    this.canClick = false;
                }
            }
        },
        changetbnum() {
            if (this.tbnum === undefined) {
                this.fee = 0;
                this.dznum = 0;
                this.canClick = false;
                return;
            }

            if (this.tbnum <= this.minwithdraw) {
                this.calcfee();
                this.dznum = 0;
                this.canClick = false;
            } else if (this.tbnum > this.minwithdraw) {
                this.calcfee();  
                this.dznum = this.tbnum - this.fee;
                if (this.inputaddress !== '') {
                    this.canClick = true;
                } else {
                    this.canClick = false;
                }
            }
        },
        changeAddress() {
            if (this.inputaddress !== '' && this.tbnum !== undefined && this.tbnum > this.minwithdraw) {
                this.canClick = true;
            } else {
                this.canClick = false;
            }            
        },
        getaddInfo() {
            let data = {
                token: sessionStorage.getItem("tk"),
            }
            this.$api.getwithdrawaddOrderInfo(data).then((res)=>{
                if(res.data.code == 200){
                    this.erc20fee = res.data.data.data.erc20_fee;
                    this.erc20min = res.data.data.data.erc20_min_withdraw_number;
                    this.trc20fee = res.data.data.data.trc20_fee;
                    this.trc20min = res.data.data.data.trc20_min_withdraw_number;
                    this.usdcfee = res.data.data.data.USDC_fee;
                    this.usdcmin = res.data.data.data.USDC_min_withdraw_number;

                    if (this.chainSelect === 'ERC20') {
                        this.feeRate = this.erc20fee;  
                        this.minwithdraw = this.erc20min;
                        this.calcfee();
                    } else if (this.chainSelect === 'TRC20') {
                        this.feeRate = this.trc20fee; 
                        this.minwithdraw = this.trc20min;
                        this.calcfee();
                    }
                } else {
                    this.$message({
                        message: this.$t('withdraw')['huoqushibai'],
                        type: 'warning'
                    });
                }
            });
        },
        addOrder() {
            this.getEmail() ;
        },
        recvdateParse(list) {
            this.recordListData = [];
            list.forEach(item => {
                var strstatus = '';
                if (item.status === '1') {
                    strstatus = this.$t('withdraw')['tijiaozhong'];
                } else if (item.status === '2') {
                    strstatus = this.$t('withdraw')['yijujue'];
                } else if (item.status === '3') {
                    strstatus = this.$t('withdraw')['yitongguo'];
                };

                var obj = {};
                obj.id = item.id;
                obj.createTime = item.createTime;
                obj.withdrawVolumes = item.withdrawVolumes;
                obj.status = strstatus;
                obj.currencyType = 'USDT';
                // item.currencyType;
                this.recordListData.push(obj);
            });
        },
        async getList() {
            this.page = 1;
            let data = {
                // token: sessionStorage.getItem("tk"),
                page: this.page,
                page_size: this.page_size,
                lang: 'big5'
            }
            this.$api.getwithdraworderList(data).then((res)=>{  
                if(res.data.code == 200){
                    this.recvdateParse(res.data.data.list) ;
                    this.totalCount = Number(res.data.data.count);
                } else {
                    this.$message({
                        message: this.$t('withdraw')['huoqushibai'],
                        type: 'warning'
                    });
                }
            });
        },
        async getmoreList() {
            let data = {
                token: sessionStorage.getItem("tk"),
                page: this.page,
                rows: this.page_size,
            }
            this.$api.getwithdraworderList(data).then((res)=>{
                if(res.data.code == 200){
                    this.recvdateParse(res.data.data.list) ;
                } else {
                    this.$message({
                        message: this.$t('withdraw')['huoqushibai'],
                        type: 'warning'
                    });
                }
            });
            // console.log('getmoreList');
        },
        handleCurrentChange() {
            this.getmoreList();
        },
        async getwithdraworderInfo(id) {
            let data = {
                // token: sessionStorage.getItem("tk"),
                id: id,
            }
            this.$api.getwithdraworderInfo(data).then((res)=>{
                if(res.data.code == 200){
                    this.dialogvolue = res.data.data.withdrawVolumes;
                    this.dialogtime = res.data.data.createTime;
                    var strstatus = '';
                    if (res.data.data.status === 1) {
                        strstatus = this.$t('withdraw')['tijiaozhong'];
                    } else if (res.data.data.status === 2) {
                        strstatus = this.$t('withdraw')['yijujue'];
                    } else if (res.data.data.status === 3) {
                        strstatus = this.$t('withdraw')['yitongguo'];
                    };
                    this.dialogstatus = strstatus;
                    this.dialogaddress = res.data.data.withdrawAddress;
                    this.dialogfee = res.data.data.serviceCharge;
                } else {
                    this.$message({
                        message: this.$t('withdraw')['huoqushibai'],
                        type: 'warning'
                    });
                }
            });
        },
        seedetail(row) {
            if (!this.dialogdetail) {
                this.dialogdetail = true;
                this.getwithdraworderInfo(row.id);
            }
        },
        async getImgCode() {
            let data = {}
            this.$api.getmakeVerifyBase64(data).then((res)=>{
                if(res.data.code == 200){
                    this.imgsrc = res.data.img_base64;
                    this.imgcodeid = res.data.id;
                } else {
                    this.$message({
                        message: this.$t('withdraw')['huoqutuxingyanzhengmashibaiqingchongxinqingqiu'],
                        type: 'warning'
                    });
                }
            });
        },
        getcodeClick() {
            // console.log(this.imgcode);
            if (this.imgcode !== undefined && this.imgcode !== '') {
                this.getEmailTextCode(this.imgcode);
            } else {
                this.$message({
                    message: this.$t('withdraw')['qingshurutuxingyanzhengma'],
                    type: 'warning'
                });
            }
        },
        async getEmailTextCode(imgcode) {
            let data = {
                token: sessionStorage.getItem("tk"),
                textcode: imgcode,
                codeuse: 7,
                useremail: this.email,
                id: this.imgcodeid
            }
            this.$api.getEmailTextCode(data).then((res)=>{
                if(res.data.code == 200){
                    this.emailcid = res.data.cid;
                    this.getcodecanClick = false;
                    this.countDown();
                } else {
                    this.imgcode = '';
                    this.getImgCode();
                    this.$message({
                        message: this.$t('withdraw')['huoquyouxiangyanzhengmashibai'] + res.data.msg,
                        type: 'warning'
                    });
                }
            });
        },
        async getEmail() {
            let data = {
                token: sessionStorage.getItem("tk"),
            }
            this.$api.getUserAuthPhoneAndEmail(data).then((res)=>{
                if(res.data.code == 200){
                    // console.log(res);
                    if (res.data.data.email && res.data.data.email.includes('@')) {
                        this.email = res.data.data.email;
                        this.dialogwithdraw = true;
                    } else {
                        this.$message({
                            message: this.$t('withdraw')['qingxianwanchengyouxiangrenzheng'],
                            type: 'warning'
                        });
                    }
                } else {
                    this.$message({
                        message: this.$t('withdraw')['huoquyouxiangshibaiqingchongxinqingqiu'],
                        type: 'warning'
                    });
                }
            });
        },
        countDown() {
            this.count = 60;
            clearInterval(this.countDowntimer);
            this.countDowntimer = setInterval(() => {
                this.count--;
                this.getcodeTitle = this.count + this.$t('withdraw')['houchongxinfasong'] ;
                if (this.count === 0) {
                    this.count = 60;
                    this.getcodecanClick = true;
                    clearInterval(this.countDowntimer);
                    this.getcodeTitle = this.$t('withdraw')['huoquyouxiangyanzhengma'] ;
                }
            }, 1000);
        },
        sureBtnClick() {
            if (this.numcode !== undefined && this.numcode !== '') {
                this.textCodeCheck(this.numcode, this.emailcid, 'withdraw');
                this.numcode = '';
            } else {
                this.$message({
                    message: this.$t('withdraw')['qingshuruyouxiangyanzhengma'],
                    type: 'warning'
                });
            }
        },
        async textCodeCheck(emailcode, emailcid, type) {
            let data = {
                token: sessionStorage.getItem("tk"),
                cid: emailcid,
                textcode: emailcode,
            }
            this.$api.textCodeCheck(data).then((res)=>{
                if(res.data.code == 200) {
                    this.withdrawaddOrder(res.data.cid, type);
                } else {
                    this.$message({
                        message: this.$t('withdraw')['youxiangyanzhengmacuowu'] + res.data.msg,
                        type: 'warning'
                    });
                }
            })
        },
        async withdrawaddOrder(checkcid, type) {
            if (type === 'withdraw') {
                if (this.CoinSelect === 'USDT') {
                    let data = {
                        token: sessionStorage.getItem("tk"),
                        name: this.chainSelect,
                        cid: checkcid,
                        address: this.inputaddress,
                        number: this.tbnum,
                        fee: this.fee,
                    }

                    this.$api.withdrawaddOrder(data).then((res)=>{
                        if(res.data.code == 200) {
                            this.dialogwithdraw = false;
                            this.inputaddress = '';
                            this.tbnum = undefined;
                            this.dznum = 0;
                            this.canClick = false;
                            this.numcode = '';
                            this.imgcode = '';
                            this.$store.dispatch('getMyLegalCurrencyAccountInfo');
                            this.getList();
                            this.getImgCode();
                        } else {
                            this.$message({
                                message: res.data.msg,
                                type: 'warning'
                            });
                        }
                    })

                } else {
                    //暂无其他币种提币需求
                }
            }
        },
        calcfee() {
            if (this.feeRate > 1) {
                this.fee = this.feeRate;
            } else {
                if (this.tbnum !== undefined && this.tbnum > 0) {
                    this.fee = baseMethods.accMul(this.feeRate, this.tbnum);
                } else {
                    this.fee = 0;
                }  
            }
        },
    },
}
</script>

<style>

.inputnum.el-input-number .el-input__inner{
    border: none;
    color:#222;
    background-color: #fff;
}

.dialogwithdraw .el-dialog,
.dialogdetail .el-dialog {
        background: #fff !important;
    }

.dialogwithdraw .el-dialog__title,
.dialogdetail .el-dialog__title {
    color: #999 !important;
    font-weight: 900;
}

</style>

<style scoped>

.showpart {
    background: #F9F9FA;
    height: auto;
    min-width: 1200px;
    margin: 0 auto;
}

.showcontent {
    padding: 0 150px;
}

.lname {
    font-size: 12px;
    font-weight: 400;
    color: #333333;
    line-height: 48px;
}

.lbtn {
    width: 260px;
    height: 48px;
    line-height: 48px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    border: 1px solid #E6ECF2;
    color: #333333;
    cursor: pointer;
}

.on {
    border: 1px solid #0059FF;
    color: #0059FF;
}

.ewm {
    margin-top: 50px;
    margin-bottom: 20px;
}

.copyicon {
    cursor: pointer;
}

.rowline {
    height: 1px;
    border-bottom: 1px solid #E6ECF2;
    margin: 54px 0 30px 0;
}

.tip {
    margin-left: 50px;
    text-align: left;
    line-height: 20px;
}

.show-box {
    width: 400px;
    background-color: #fff;
    max-height: 700px;
    overflow-y: scroll;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
}

.show-box::-webkit-scrollbar {
    display: none;
}

.delist {
    margin: 10px 0;
    font-size: 16px;
    line-height: 40px;
}

.delist .type2 {
    display: none;
}

.delist .flex_row_between div:nth-child(1) {
    color: #9aa5b5;
}

.CoinUSDT,
.CoinUSDC{
    width: 88px;
    height: 36px;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    line-height: 36px;
    color: #666666;
    cursor: pointer;
}

.kuang {
    padding: 6px;
    background: #FFFFFF;
    border: 1px solid #E6ECF2;
    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.03);
    border-radius: 2px;
}

.onselect {
    color: #FFFFFF;
    background: #1772F8;
}

.inputblock {
    width: 100%;
    height: 48px;
    background: #FFFFFF;
    border: 1px solid #CCCCCC;
    border-radius: 2px;
    line-height: 48px;
    text-indent: 12px;
}

.kyye {
    font-size: 12px;
    font-weight: 400;
    color: #999999;
    line-height: 21px;
}

.dzsl {
    font-size: 16px;
    font-weight: 400;
    color: #333333;
    line-height: 21px;
    margin: 24px 0;
}

.drawbtn {
    width: 234px;
    height: 48px;
    background: #0059FF;
    border: 1px solid #0059FF;
    border-radius: 2px;
    line-height: 48px;
    font-size: 16px;
    font-weight: 400;
    color: #FFFFFF;
    text-align: center;
    cursor: pointer;
    margin-bottom: 28px;
}

.noclick {
    pointer-events: none;
    background: rgb(208, 217, 222);
    border: none;
}

.tips {
    text-align: left;
    line-height: 20px;
    color: #999;
}

.all {
    font-size: 14px;
    font-weight: 400;
    color: #0059FF;
    line-height: 21px;
    cursor: pointer;
    border-left: 1px solid #CCCCCC;
    padding: 0 20px;
}

.address .el-input__inner{
    border: 1px solid #CCCCCC;
    border-radius: 0px;
    height: 50px;
    color:#222;
}

.Change-frame {
    width: 480px;
    /* height: 500px; */
    /* margin: 308px auto; */
    background-color: #fff;
    max-height: 700px;
    /* overflow-y: scroll; */
    /* overflow-x: hidden; */
    /* -webkit-overflow-scrolling: touch; */
}

.Change-input {
    font-size: 14px;
    color: #666;
    margin-bottom: 30px;
}

.authimg {
    height: 40px;
    width: auto;
    max-width: 130px;
    margin: 12px 0 0 10px!important;
}

.Change {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
}

.getcode {
    background: #08BD98;
    box-shadow: 0px 2px 4px 0px rgb(21 85 73 / 35%);
    color: #fff;
    line-height: 40px;
    text-align: center;
    margin-top: 12px;
    width: 160px;
    margin-left: 10px;
    cursor: pointer;
}

.but-determine {
    height: 40px;
    text-align: center;
    line-height: 40px;
    background-color: #08BD98;
    color: #fff;
    width: 126px;
    border-radius: 2px;
    cursor: pointer;
}

.inputnum .el-input-number.is-disabled /deep/ .el-input__inner{
    border: none;
    color:#999;
    background-color: #fff;
}

::v-deep .inputnum .el-input-number__decrease{
    border: none;
    color: #222;
    background-color: #eee;
}

::v-deep .inputnum .el-input-number__increase{
    border: none;
    color: #222;
    background-color: #eee;
}

::v-deep .inputnum .el-input-number__decrease.is-disabled{
    color: #ccc;
}

::v-deep .inputnum .el-input-number__increase.is-disabled{
    color: #ccc;
}

::v-deep .inputnum .el-input-number.is-disabled .el-input-number__decrease{
    color: #ccc;
}

::v-deep .inputnum .el-input-number.is-disabled .el-input-number__increase{
    color: #ccc;
}

.flex_row_between {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.flex_col_center {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.flex_row_left {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.flex_row_around {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.flex_row_center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.fontSize25 {
    font-size: 25px;
}

.text_center {
  text-align: center
}


</style>
<template>
    <div>
        <div style="width: 85%;margin: 0 auto;margin-top: 20px;">	
            <div class="my_headr" @click="goBack()">{{i18n('huidaomulu')}}</div>
            
            <div class="showpart" style="border-bottom: 1px solid #ccc;color:white">
                <div class="top_banlance">
                    <div class="fucMoney ty_line">
                        <p>{{i18n('dangqianchiyou')}}{{fucDetails.coinCodeNo}}</p>
                        <div class="fucBalance" >
                            <span>{{fucBalance.length>0?fucBalance[0].balance:'0.00'}}</span>
                            <i>≈ {{fucBalance.length>0?fucBalance[0].currentValue:'0.000000'}} USDT</i>
                        </div>
                    </div>
                    <div class="dolloarMoney" style="display: none;">
                        <div class="ty_line">
                            <p>{{i18n('keyong')}}(USDT)</p>
                            <div>
                                <span class="otcMoney">0.00</span>
                            </div>
                        </div>
                        <div class="ty_line">
                            <p>{{i18n('keduihuan')}}ICO</p>
                            <div>
                                <span class="otc_canuse">0.00</span>
                            </div>
                        </div>
                    </div>
                </div> 
                
                <div class="bom_main" >
                    <div class="left_mess">
                        <div class="fcm_3 displayCenter">
                            <div class="newP">{{i18n('shishihangqing')}} <i >{{fucList.length>0?fucList[0].price:'- - '}}</i></div>
                            <!-- <div class="newRate color_up">- -</div> -->
                        </div>
                        <div class="fcm_4">
                            <div id="myCharts" style="height: 300px;width:500px"></div> 
                        </div>
                    </div>
                    <div class="right_exchange">
                        <div style="line-height: 30px;">
                            ICOB研發的Multidimensional Space是一個開放的、社交型的VR世界。 ICO是基於以太坊區塊鏈的Multidimensional Space內寘加密貨幣代幣。 Multidimensional Space將會實現一個完全去中心化的互聯網，用戶擁有完全控制權力。 它構想的互聯網是每個人的身份和數據是由自己來掌控-不受任何中央機构的影響。 加速5G發展的同時，Multidimensional Space也將成為Web2. 0和Web3. 0的橋樑總和（Web3.0子領域包括DID、NFT、DAO、DeFi、跨鏈等）。 ICO的用途將是用於SGCEX Global 生態系統和去中心化交易所需的燃料消耗，如用ICO抵扣SGCEX Global交易手續費，以及Multidimensional Space的交易，例如傳送系統和去中心化的支付，特別是用戶與用戶之間的交易。
                        </div>
                    </div>
                </div>   
            </div>
            
            <div class="my_content">
                <div class="content_header">
                    <div style="float: left;padding-top: 20px;">{{i18n('shishihangqing')}}:<span class="sshq">{{fucList.length>0?fucList[0].price:'- - '}}</span> USDT/{{fucDetails.coinCodeNo}}</div>
                    <div style="float: right;">
                        <!-- <a href="recharge.html"><div style="color: #1763B6;">USDT 充值</div></a> -->
                        <div >{{i18n('yue')}} <span style="font-size: 20px;font-weight: 600;margin-top: 10px;" class="otcMoney">{{legalBalance}}</span>USDT</div>
                    </div>
                </div>
                <div class="content_main">
                    <div class="main_img">
                        <img src="@/assets/images/subdet.png" class="mmimg">
                        
                    </div>
                    <div class="content_mm" v-if="fucDetails.fucProductName">
                        <div>{{i18n('biaoti')}}：<span class="cont_mm1">{{fucDetails.fucProductName}}</span></div>
                        <div>{{i18n('fashoushuliang')}}：<span class="cont_mm2" >{{parseFloat(fucDetails.fucProductQuota)?fucDetails.fucProductQuota:i18n('meiyouxianzhi')}}</span></div>
                        <div>{{i18n('shengyushuliang')}}：<span class="cont_mm3">{{fucDetails.fucProductSurplus}}</span></div>
                        <div>{{i18n('kaishishijian')}}：<span class="cont_mm4">{{fucDetails.startTime | formatDate}}</span></div>
                        <div style="display: flex;">
                            <div>{{i18n('jindu')}}：</div>
                            <div class="layui-progress" style="width: 200px;height:12px" >
                            
                                <el-progress  :percentage="progresst" ></el-progress>
                        
                            </div>
                        </div>
                    </div>
                    <div style="float: right;">
                        <div class="flex_row_left MT12" style="margin-top: 30px;">
                            <div class="flex1 flex_row_between inputpart">
                                <div style="">
                                    <el-input :placeholder="i18n('goumaijine')" @input="srPayMoney" type="number" v-model="payMoney">
                                        <template slot="append">
                                            <div style="width:40px;text-align:center">USDT</div>
                                        </template>
                                    </el-input>
                                </div>
                            </div>
                        </div>
                        <div class="flex_row_left MT12" style="margin-top: 30px;">
                            <div class="flex1 flex_row_between inputpart">
                                <div style="">
                                    <el-input :placeholder="i18n('goumaishuliang')" :disabled="true" v-model="paySl">
                                        <template slot="append" >
                                            <div style="width:40px;text-align:center">{{i18n('ge')}}</div>
                                        </template>
                                    </el-input>
                                </div>
                            </div>
                        </div>
                        <div class="flex_row_left" style="margin-top: 30px;">
                            <div class="common_radio "></div>
                            <div class="isure" style="color: white;">{{i18n('woyiyuedu')}}<span class="" style="color: #1763B6;cursor: pointer;" @click="step11Show = true">《{{i18n('daibigoumaixieyi')}}》</span></div>
                        </div>
                        <div class="transferbtn" style="margin-top: 30px;background-color: #1763B6;" @click="goBuy">{{i18n('shifoyaogoumai')}}</div>
                    </div>
                </div>
            </div>
            <!-- <div class="tablepart" style="margin-top: 100px;">
               <div class="tabhead">記錄</div>
               <div class="nolist">
                  
                   <img class="nolistimg" src="./images/wudingdan.png" alt="">
                   <div class="fontSize14 color_666 text_center" style="margin-bottom: 150px;">暫無記錄</div>
               </div>
               <div class="hilist">
                    <table class="retab">
                       <tr>
                           <th >類型</th>
                           <th>SGCB數量</th>
                           <th>購買金額(USDT)</th>
                           <th>時間</th>
                       </tr>
                   </table>
                   <table class="retabm retab color_333">
                       
                   </table>
                   <div class="text_right" id="orderpaging"></div>
               </div>
           </div> -->
            <div class="step11 flex_row_center" v-if="step11Show">
                <div class="show-box" style="height: 500px;">
                    <div class="box-title flex_row_between pos_relative" style="position: fixed;width: 400px;background-color: white;">
                        <div></div>
                        <img src="@/assets/images/guanbi.png" class="close11" @click="step11Show = false">
                    </div>
                    <div style="padding: 0 20px;line-height: 30px;color:#000000;margin-top: 100px;">
                        
                        
                        All users subscribe to the ICO fairly in accordance with the rules . To ensure the principle of truthfulness and transparency, SGCEX keeps all original subscription records for possible subsequent third-party audits.
                        Once   ICO    is    approved    for     full     subscription     by     users,     we will provide withdrawal service within 7 working days . To prevent users from not carefully understanding the hasty investment, we still set the entry threshold for	project	investment	users.	Only	users	who participate through KYC participate in the subscription. Please be sure to carefully understand the project and read the subscription rules before participating.
                        Conditions for users to participate in the project</br>
                        
                        ·Register an SGCEX account and log in</br>
                        
                        ·Fill in your identity information to complete KYC verification</br>
                        
                        ·Recharge  USDT to an SGCEX account</br>
                        
                        · Receive an in-app purchase invitation. </br>
                        
                        
                        a.Subject scope</br>
                        
                        These Terms of Use are jointly concluded by you and the SGCEX Foundation , and have contractual effects for you and the SGCEX Foundation .</br>
                        
                        b.Scope of Terms</br>
                        
                        Due to the rapid development of the digital currency ecosystem and the SGCEX ecosystem, the terms of use concluded between you and SGCEX cannot be completely listed and
                        cover all your rights and obligations with SGCEX . Existing agreements cannot guarantee full compliance with future development needs.</br>
                        
                        c.Time frame</br>
                        
                        SGCEX reserves the right to decide, modify or change these Terms of Use at any time. SGCEX will notify changes by updating the revised terms on the webpage and modifying the [latest revision date] displayed on this page. Any and all modifications or changes to these Terms of Use will be published on the website or sent to the user immediately. Therefore, if you continue to use the SGCEX service, you accept the revised agreement and rules. If you do not agree to any changes to these Terms of Use, please stop using SGCEX services immediately. SGCEX hopes that you will frequently review these Terms of Use to ensure that you understand the terms and conditions that apply to your access and use of SGCEX services.</br>
                        d.Prohibited use range</br>
                        
                        By accessing and using the SGCEX service, you represent and warrant that you have not been included in any trade or economic sanctions list (such as the UN Security Council sanctions list) and have not been designated as a "special" Designated Nationals ".</br>
                        SGCEX is an important part of the SGCEX ecological chain, mainly as a global online digital asset trading platform, providing users with various types of digital
                        asset-related trading platform services, financing services, technical services and
                        other services. According to the further detailed provisions in Article 3 below, users
                        must register and open an account with SGCEX and deposit digital assets before
                        starting trading. Users can apply for the withdrawal of digital assets, but they must
                        comply with the restrictions set forth in these Terms of Use. </br>
                        Information about the SGCEX service may change without notice, and the
                        main purpose of providing this information is to help users make independent
                        decisions. SGCEX does not provide investment or consulting advice, nor is it
                        responsible for the use or interpretation of information on SGCEX or other
                        communication media. All users who use SGCEX services must understand that
                        digital asset trading involves risks. SGCEX expects all users to proceed cautiously and conduct transactions responsibly within their ability.</br>
                        
                        
                        3.SGCEX account registration and requirements</br>
                        
                        
                        a.Register</br>
                        
                        All users must apply for an SGCEX account before using the SGCEX service . When registering an SGCEX account, you must provide your real name, email address and password, and accept these Terms of Use, Privacy Statement and other platform
                        rules. SGCEX may refuse to open an account for you at its discretion. You agree to provide complete and accurate information when opening an account, and agree to update any information you provide to SGCEX in a timely manner to maintain the integrity and accuracy of the information. Only one user can be registered at a time, but each user (including any user belonging to a company or legal entity) can only open one master account at a time.</br>
                        
                        b.Qualification</br>
                        
                        
                        By registering to use an SGCEX account, you declare and warrant that (a) if you are an individual, you have reached the age of 18 or reached the legal age to enter into binding contracts in accordance with applicable laws; (b) are fully legal acts with the terms of use Capable and authorized individuals, legal persons or other organizations; ( c ) You currently do not have an SGCEX account;</br>
                        
                        c.User authentication</br>
                        
                        
                        By registering your SGCEX account, you agree to provide the personal information requested for identity verification. We will collect, use and share this information in accordance with the "Privacy Statement" we have published. In addition to providing this information, in order to comply with industry standards for global data storage, you agree to allow us to keep records of this information for the duration
                        After registration, you must ensure that the information is true and complete, and at the same time, if there is any change, update it in time. If there is any reason to suspect that any information you provided is wrong, untrue, outdated or
                        incomplete, SGCEX has the right to send you a notice requesting corrections, directly delete the relevant information, and (as the case may be) terminate providing you All or part of the SGCEX service. If you can not reach you by contacting the information provided, You will be liable SGCEX generated during the
                        service SGCEX any losses or expenses take full responsibility. You hereby confirm and agree that if there is any change, you are obliged to update all information at any time.
                        By creating an SGCEX account, you hereby agree to:</br>
                        
                        
                        1.If you know anyone unauthorized use of your SGCEX account and password   or any other violation of security rules, you will immediately notify SGCEX ;</br>
                        2.You will strictly abide by all of SGCEX 's safety, certification, transaction, charging, and withdrawal mechanisms or procedures;</br>
                        3.At the  end  of  each  visit,  you  will  take  appropriate  steps  to  log  out  of SGCEX and log out.</br>
            
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
  import * as echarts from 'echarts';
export default {
    
    data() {
        return {
           selType:'',
           fucList:[],
           step11Show:false,
           myChart: {},
            xData: [], //横坐标
            yData: [], //人数数据
            myChartStyle: { float: "left", width: "100%", height: "400px" }, //图表样式
            legalBalance:0,
            fucBalance:[],
            mcid :this.$route.query.cid,
            fucDetails:{},
            progresst:0,
            payMoney:'',
            paySl:''
        };
    },
    filters:{
        formatDate(now) {
            var needdate = new Date(now * 1000);
            var Y = needdate.getFullYear() + '-';
            var M = (needdate.getMonth() + 1 < 10 ? '0' + (needdate.getMonth() + 1) : needdate.getMonth() + 1) + '-';
            var D = needdate.getDate()<10? '0'+needdate.getDate():needdate.getDate()+ ' ';
            //      年 月 日 时 分 秒
            return Y + M + D;
        }
    },
    mounted() {
       
        this.$root.$on('tradelogin', (message) => {
            if (message.loginStatus) {
                this.getFucList();
                this.getAllAccountInfo();
                this.getUserFucCoinAccountsFun()
                this.getProductList()
            } 
        });

        //判断一下套接字的连接状态
        if (this.$store.state.loginStatus) {
            this.getFucList();
            this.getAllAccountInfo();
            this.getUserFucCoinAccountsFun()
            this.getProductList()
        };
    },
    beforeDestroy() {
        
    },
    
    methods: {
        format_M_D(timestamp) {
            var date = new Date(timestamp * 1000);
            var Y = date.getFullYear() + '-';
            var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
            var D = (date.getDate() < 10 ? '0' + (date.getDate()) : (date.getDate())) + ' ';
        
            var h = date.getHours() < 10 ? '0' + date.getHours() : date.getHours() ;
            var m = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
            var s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
        
            return M + D
        },
        initEcharts() {
            const option = {
                xAxis: {
                data: this.xData
                },
                tooltip: {
                    trigger: 'axis'
                },
                yAxis: {
                    type: 'value'
                },
                series: [
                {
                    data: this.yData,
                    type: "line" // 类型设置为折线图
                }
                ]
            };
            this.myChart = echarts.init(document.getElementById("myCharts"));
            this.myChart.setOption(option);
            //随着屏幕大小调节图表
            window.addEventListener("resize", () => {
                this.myChart.resize();
            });
        },
        goBack(){
            this.$router.go(-1);
        },
        getFucList(){
            let  data = {
	            fid:1
	        }
            this.$api.getFucHistoryInfo(data).then((res)=>{
                this.fucList = res.data.data;
                for(let i=0;i<20;i++){
                    this.xData.push(this.format_M_D(this.fucList[i].time))
                    this.yData.push(this.fucList[i].price)
                }
                this.xData.reverse()
                this.yData.reverse()
                this.initEcharts()
            })
        },
        getProductList(){
            let data = {
                fucId:1,
				pid :this.mcid
            }
            this.$api.fucproductList(data).then((res)=>{
               this.fucDetails = res.data.data.list[0]
               if(parseFloat(res.data.data.list[0].fucProductQuota)>0){
                    this.progresst = (parseFloat(res.data.data.list[0].fucProductQuota)-parseFloat(res.data.data.list[0].fucProductSurplus))/parseFloat(res.data.data.list[0].fucProductQuota)*100
                    this.progresst  = parseInt(this.progresst)
               }else{
                    this.progresst = 0
               }
            })
        },
        getAllAccountInfo(){
            this.$api.getMyLegalCurrencyAccountInfo({}).then((res)=>{
                this.legalBalance = res.data.data.legalBalance
            })
        },
        getUserFucCoinAccountsFun(){
            this.$api.getUserFucCoinAccounts({}).then((res)=>{
                this.fucBalance = res.data.data
            })
        },
        srPayMoney(e){
            if(e){
                if(parseFloat(e)>parseFloat(this.legalBalance)){
                    this.payMoney = parseFloat(this.legalBalance)
                    this.paySl = parseFloat(this.legalBalance)/parseFloat(this.fucList[0].price)
                }else{
                    this.paySl = parseFloat(e)/parseFloat(this.fucList[0].price)
                }
            }else{
                this.paySl = 0
            }
        },
        goBuy(){
            if(!this.paySl){
                this.$message.error(this.$t("subscription")['qingshurugoumaijine']);
            }else{
                this.$confirm(this.$t("subscription")['shifoyaogoumai'], this.$t("subscription")['tishi'], {
                    confirmButtonText: this.$t("subscription")['queding'],
                    cancelButtonText: this.$t("subscription")['quxiao'],
                    type: 'warning'
                    }).then(() => {
                    const loading = this.$loading({
                        lock: true,
                        text: 'Loading',
                        spinner: 'el-icon-loading',
                        background: 'rgba(0, 0, 0, 0.7)'
                    });    
                    let data = {
                        fid:1,
                        pid :this.mcid,
                        tradeNum:this.paySl,
                    }
                    this.$api.buyFucCoin(data).then((res)=>{
                        if(res.data.code==200){
                            this.$message({
                                message: res.data.msg,
                                type: 'success'
                            });
                            this.getAllAccountInfo();
                            this.getUserFucCoinAccountsFun();
                            this.getProductList();
                            this.paySl = '';
                            this.payMoney = ''
                        }else{
                            this.$message.error(res.data.msg);
                        }
                        loading.close();
                    })
                })
                
            }
            
            
        }
    },
    computed: {
        i18n() {
            return function(name) {
                // index找到对应页面的obj name是onj的key
                return this.$t("subscription")[name];
            }
		},
    },
}
</script>

<style scoped>
@import '@/css/recharge.css';
body{
    background-color: rgb(38,34,57) ;
    color: white;
    padding-bottom: 50px;
}
.my_headr{
    width: 15%;
    color: #1763B6;
    text-align: center;
    line-height: 30px;
    font-size: 15px;
    border: 1px solid #1763B6;
    height: 30px;
    cursor: pointer;
}
.my_content{
    width: 100%;
    margin-top: 40px;
}
.content_header{
    overflow: hidden;
}
.content_main{
    width: 100%;
    margin-top: 20px;
    overflow: hidden;
}
.transferbtn,
.pfexbtn,
.fitlossbtn {
    height: 44px;
    line-height: 44px;
    text-align: center;
    margin-top: 20px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 400;
    color: #FFFFFF;
    background: #08BD98;
    border-radius: 2px;
    width: 100%;
}
.main_img{
    
    float: left;
}
.mmimg{
    width: 300px;
    height: 300px;
}
.content_mm{
    float: left;
    margin-left: 20px;
}
.content_mm div{
    line-height: 60px;
}
.retab th{
    width: 25%;
}
.retab td{
    width: 25%;
}
.layui-progress-text{
    top:-24px;
    /* color: white; */
    font-weight: 600;
    color: #000000;
}
.close11{
    cursor: pointer;
}
.showpart{
    height: auto;
    overflow: hidden;
    border: none;
    background-color: rgb(38,34,57) !important;
    margin-top: 20px;
}
/* .showpart>div{
    padding: 15px 20px;
} */
.showpart i{
    font-style: normal;
}
.top_banlance{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 20px;
    border-bottom: 1px solid #ccc;
}
.ty_line p{
    font-size: 13px;
    /* color: #999; */
    margin-bottom: 10px;
}
.ty_line div {
    font-size: 18px;
    /* color: #333; */
    font-weight: bold;
}
.ty_line div i{
    font-size: 13px;
    /* color: #999; */
}
.dolloarMoney{
    display: flex;
    align-items: center;
}
.dolloarMoney>div{
    padding-left: 40px;
}
.bom_main>div{
    box-sizing: border-box;
    float: left;
    width: 50%;
    padding: 15px 20px;
}
.left_mess{
    border-right: 1px solid #ccc;
}
.displayCenter{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.newP i {
    color: #D99D02;
    font-size: 16px;
}
.fcm_4 {
    height: 200px;
    padding-bottom: 25px;
}
.chartitem {
    width: 100%;
    height: 200px;
}	
</style>

import Vue from "vue";
import Vuex from "vuex";
import { writeNewStyle } from "@/tools/theme/index";
import variables from "@/assets/scss/var.scss";
import tradeWSModule from "@/store/trade-ws";
import quotesWSModule from "@/store/quotes-ws";
import api from '@/utils/api'

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        themeColor: sessionStorage.getItem("themeColor") || variables.themeColor,
        userWallet: {
            balance: 0, //余额
            marginBalance: 0, //账户权益
            marginPosition: 0, //持仓保证金
            marginFrozen: 0, //冻结保证金
            marginAvailable: 0, //可用保证金
            profitReal: 0, //已实现盈亏
            profitUnreal: 0, //未实现盈亏
            riskRate: 0, //保证金率
            followBalance: 0, //跟单账户余额
            liquidationPrice: 0, //预估强平价
            canuseMoney: 0, //可用余额
            userType: 0,
            LegalCurrency: 0, //法币账户余额通过API获取
            calcprofit: 0, //本地计算浮动盈亏
            calcprofitWh: 0, //本地计算浮动盈亏
            calcprofitMg: 0, //本地计算浮动盈亏
        },
        hqlist: [], //行情列表   
        contractList: [], //合约列表
        leverList: [], //杠杆
        holdList: [], //持仓单
        holdOrderList: [], //交易页面使用的持仓单
        EntrustList: [], //委托单
        EntrustorderList: [], //交易页面使用的委托单
        DealList: [], //成交单
        DealOrderList: [], //交易页面使用的成交单
        ConditionList: [], //条件单
        ConditionOrderList: [], //交易页面使用的条件单
        AutoProfitLossList: [], //自动止盈止损列表
        tradeFee: {
            //交易手续费
            open: 0,
            close: 0,
        },
        exchangeRate: 6.5, //汇率
        loginStatus: false,
        username: "",
        userpass: "",
    },
    mutations: {
        setTheme(state, data) {
            state.themeColor = data || variables.themeColor;
            sessionStorage.setItem("themeColor", state.themeColor);
        },
    },
    actions: {
        changeThemeStyle({ state }) {
            writeNewStyle(state.themeColor);
        },
        logout({ state }) {
            state.loginStatus = false;
        },
        async getMyLegalCurrencyAccountInfo({ state }) {
            let data = {
                token: sessionStorage.getItem("tk"),
            }
            api.getMyLegalCurrencyAccountInfo(data).then((res)=>{
                if(res.data.code == 200){
                    state.userWallet.LegalCurrency = Number(res.data.data.legalBalance);
                }
            })
        },
        ClearContractState({ state }) {
            state.holdOrderList = [];
            state.EntrustorderList = [];
            state.DealOrderList = [];
            state.ConditionOrderList = [];
            state.username = '';
            state.userpass = '';
            state.userWallet.balance = 0;           //余额
            state.userWallet.marginBalance = 0;     //账户权益
            state.userWallet.marginPosition = 0;    //持仓保证金
            state.userWallet.marginFrozen = 0;      //冻结保证金
            state.userWallet.marginAvailable = 0;   //可用保证金
            state.userWallet.profitReal = 0;        //已实现盈亏
            state.userWallet.profitUnreal = 0;      //未实现盈亏
            state.userWallet.riskRate = 0;          //保证金率
            state.userWallet.followBalance = 0;     //跟单账户余额
            state.userWallet.liquidationPrice = 0;  //预估强平价
            state.userWallet.canuseMoney = 0;       //可用余额
            state.userWallet.userType = 0;
            state.userWallet.LegalCurrency = 0;     //法币账户余额通过API获取
            state.userWallet.calcprofit = 0;        //本地计算浮动盈亏
            state.userWallet.calcprofitWh = 0;      //本地计算浮动盈亏
            state.userWallet.calcprofitMg = 0;      //本地计算浮动盈亏
            state.loginStatus = false;
        },
    },
    modules: {
        tradeWS: tradeWSModule,
        quotesWS: quotesWSModule,
    },
});

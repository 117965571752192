<template>
    <div class="SecurityCenter">
        <div>
            <div class="flex_row_left" style="height: 50px; background-color: #eee;">
                <el-page-header 
                    @back="goBack" 
                    :content="i18n('anquanzhongxin')"
                    :title="i18n('fanhui')"
                    style="margin-left: 10px;">
                </el-page-header>   
            </div>
        </div>

        <div class="list" >
            <div class="list-top">
                <div class="touxiang pos_relative" @mouseenter="headimgenter" @mouseleave="headimgover">
                    <el-avatar :size="56">
                        <img :src="headimg"/>
                    </el-avatar>
                    <transition name="el-fade-in">
                        <label class="abinput1 flex_row_center" v-show="headimgShow">
                            <i class="el-icon-camera color_white"></i> 
                            <el-upload
                                class="avatar-uploader"
                                :action = "uploadURL"
                                :show-file-list="false"
                                :on-success="handleAvatarSuccess"
                                :before-upload="beforeAvatarUpload">
                            </el-upload>
                        </label>
                    </transition>
                </div>
                <div class="list-shi">
                    <div class="he flex_row_left">
                        <div class="flex_row_left showname" v-show="shownickname">
                            <h2> {{ userName ? userName : '--' }} </h2>
                            <img class="vipicon" src="@/assets/images/vip.png" alt="">
                        </div>
                        <div class="showinput flex_row_left" v-show="!shownickname">
                            <!-- <input name="nickname" placeholder="请输入昵称" class="nickinput" autocomplete="off"> -->
                            <el-input v-model="nickname" class="nickinput" placeholder="i18n('qingshurunicheng')" size="mini"></el-input>
                            <div class="surebtn bg_green color_white" @click="changeNikname"> {{ i18n('queding')}} </div>
                        </div>
                        <i class="el-icon-edit iconfont" style="margin-left: 20px;" @click="nicknameClick"></i>
                    </div>
                    <div id="copy" type="button" onclick="copy()" class="list-id flex_row_center" style="margin-top: 10px;">
                        id: {{ uid ? uid : '--' }}
                    </div>
                </div>
            </div>
        </div>

        <div class="list-btton">
            <div class="security">
                <div class="security-top"> {{ i18n('anquanshezhiguanli')}} </div>
                <div class="security-but">
                    <ul>
                        <li>
                            <div class="denglu">
                                <i class="iconfont el-icon-lock img-deng color_green"></i>
                                <p style="margin-bottom: 10px;"> {{ i18n('denglumima')}} </p>
                                <p style="margin-left: 43px;font-size: 14px;color: #999;"> {{ i18n('tongguoshezhidenglumimaninkeyishiyongzhanghaomimazhijiedenglu')}} </p>
                            </div>
                            <div class="modify modify1 flex_row_center" @click="changepassword"> {{ i18n('xiugai')}} </div>
                        </li>
                        <li>
                            <div class="denglu">
                                <i class="iconfont el-icon-wallet img-deng color_green"></i>
                                <p style="margin-bottom: 10px;"> {{ i18n('zijinmima')}} </p>
                                <p style="margin-left: 43px;font-size: 14px;color: #999;"> {{ i18n('yongyubaohuzijinanquan')}} </p>
                            </div>
                            <div class="modify modify2 flex_row_center" @click="changefundpassword"> {{ (payAuthentication === 1 || payAuthentication === 3) ? i18n('xiugai') : i18n('shezhi') }} </div>
                        </li>
                        <li>
                            <div class="denglu">
                                <i class="iconfont el-icon-message img-deng color_green"></i>
                                <p style="margin-bottom: 10px;"> {{ i18n('youxiangrenzheng')}} </p>
                                <p style="margin-left: 43px;font-size: 14px;color: #999;"> {{ i18n('yongyudenglutibizhaohuimimaxiugaianquanshezhishijinxinganquanyanzheng')}} </p>
                            </div>
                            <div class="alreadyCertify" v-show="emailAuthentication === 1"> {{ i18n('yirenzheng')}} </div>
                            <div class="modify modify4 flex_row_center" v-show="emailAuthentication === 0" @click="emailauthClick"> {{ i18n('renzheng')}} </div>
                        </li>
                        <li>
                            <div class="denglu">
                                <i class="iconfont el-icon-postcard img-deng color_green"></i>
                                <p style="margin-bottom: 10px;"> {{ i18n('shimingrenzheng')}} </p>
                                <p style="margin-left: 43px;font-size: 14px;color: #999;"> {{ i18n('yongyumaibimaibitibichongbihuazhuanshijinxinganquanrenzheng')}} </p>
                            </div>
                         
                            <div class="alreadyCertify alredyrz" v-show="trueNameAuthentication === 1"> {{ i18n('yirenzheng')}} </div>
                            <div class="alreadyCertify waitsh" v-show="trueNameAuthentication === 2"> {{ i18n('daishenhe')}} </div>
                            <div class="alreadyCertify color_red confuse" v-show="trueNameAuthentication === 3"> {{ i18n('yibohui')}} </div>
                            <div class="modify modify6 flex_row_center" v-show="trueNameAuthentication === 0 || trueNameAuthentication === 3" @click="verificationclick"> {{ i18n('renzheng')}} </div>
                    
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <el-dialog :title="i18n('xiugaidenglumima')" 
        class="dialogchangepassword" 
        :visible.sync="dialogchangepassword" 
        :close-on-click-modal=false
        :close-on-press-escape=false
        width="30%">
            <div class="flex_row_center" style="font-size: 14px;">
                <div style="width: 400px; background-color: #fff;">
                    <div class="Change-input" style="margin-bottom: 20px;"> {{ i18n('yuandenglumima')}} <br>
                        <!-- <input class="oldpsw" type="password" /> -->
                        <el-input 
                            v-model="oldpassword" 
                            style="width: 400px; margin-top: 12px;"
                            :placeholder="i18n('qingshurujiumima')"></el-input>
                    </div>
                    <div class="Change-input" style="margin-bottom: 20px;"> {{ i18n('xinmima')}} <br>
                        <!-- <input class="newpsw" type="password" /> -->
                        <el-input 
                            v-model="newpassword" 
                            style="width: 400px; margin-top: 12px;"
                            :placeholder="i18n('qingshuruxinmima')"></el-input>
                    </div>
                    <div class="Change-input" style="margin-bottom: 20px;"> {{ i18n('quedingxinmima')}} <br>
                        <!-- <input class="surenewpsw" type="password" /> -->
                        <el-input 
                            v-model="newpassword2" 
                            style="width: 400px; margin-top: 12px;"
                            :placeholder="i18n('qingzaicishuruxinmima')"></el-input>
                    </div>
                    <div class="Change-input" style="margin-bottom: 20px;"> {{ i18n('tuxingyanzhengma')}} <br>
                        <div class="flex_row_left">
                            <!-- <input type="text" class="imgcode flex1 imgcode_pwd" placeholder="请输入图形验证码" /> -->
                            <el-input 
                                v-model="imgcode" 
                                style="width: 220px; margin-top: 12px;"
                                :placeholder="i18n('qingshurutuxingyanzhengma')"></el-input>
                            <img @click="getImgCode" class="authimg" :src="imgsrc" alt="" style="cursor: pointer; margin-left: 20px;">
                        </div>
                    </div>
                    <div class="Change-input" style="margin-bottom: 20px;"> {{ i18n('yanzhengma')}} <br>
                        <div class="flex_row_left">
                            <!-- <input type="text" class="numcode flex1 numcode_pwd" placeholder="请输入验证码" /> -->
                            <el-input 
                                v-model="numcode" 
                                style="width: 220px; margin-top: 12px;"
                                :placeholder="i18n('qingshuruyanzhengma')"></el-input>
                            <div :class="{'getcode': true, 'noclick': !getcodecanClick}" 
                                data-type="4" 
                                @click="getcode('4')" > 
                                {{ getcodeTitle }} 
                            </div>
                        </div>
                    </div>

                    <div class="Change-cancel flex_row_center">
                        <div class="but-determine changePsw" @click="changePswclick"> {{ i18n('queding')}} </div>
                    </div>
                </div>
            </div>
        </el-dialog>

        <el-dialog :title="i18n('xiugaizijinmima')" 
        class="dialogchangefundpassword" 
        :visible.sync="dialogchangefundpassword" 
        :close-on-click-modal=false
        :close-on-press-escape=false
        width="30%">
            <div class="flex_row_center" style="font-size: 14px;">
                <div style="width: 400px; background-color: #fff;">
                    <div class="Change-input" style="margin-bottom: 20px;"> {{ i18n('zijinmima')}} <br>
                        <!-- <input type="password" class="fundPassword" maxlength="6" /> -->
                        <el-input 
                            v-model="fundPassword" 
                            style="width: 400px; margin-top: 12px;"
                            :placeholder="i18n('qingshuruzijinmima')"></el-input>
                    </div>
                    <div class="Change-input" style="margin-bottom: 20px;"> {{ i18n('querenzijinmima')}} <br>
                        <!-- <input type="password" class="surefundPassword" maxlength="6" /> -->
                        <el-input 
                            v-model="fundPassword2" 
                            style="width: 400px; margin-top: 12px;"
                            :placeholder="i18n('qingzaicishuruzijinmima')"></el-input>
                    </div>
                    <div class="Change-input otctype" 
                        style="margin-bottom: 20px;"
                        v-show="payAuthentication === 1 || payAuthentication === 3"> 
                        {{ i18n('tuxingyanzhengma')}} <br>
                        <div class="flex_row_left">
                            <!-- <input type="text" class="imgcode flex1 imgcode_money" placeholder="请输入图形验证码" />
                            <img onclick="getImgCode()" class="authimg" src="" alt=""> -->
                            <el-input 
                                v-model="imgcode" 
                                style="width: 220px; margin-top: 12px;"
                                :placeholder="i18n('qingshurutuxingyanzhengma')"></el-input>
                            <img @click="getImgCode" 
                                class="authimg" 
                                :src="imgsrc" alt="" 
                                style="cursor: pointer; margin-left: 20px;">
                        </div>
                    </div>
                    <div class="Change-input otctype" 
                        style="margin-bottom: 20px;"
                        v-show="payAuthentication === 1 || payAuthentication === 3"> 
                        {{ i18n('yanzhengma')}} <br>
                        <div class="flex_row_left">
                            <!-- <input type="text" class="numcode flex1 numcode_money" placeholder="请输入验证码" />
                            <div class="getcode" data-type="5">获取验证码</div> -->
                            <el-input 
                                v-model="numcode" 
                                style="width: 220px; margin-top: 12px;"
                                :placeholder="i18n('qingshuruyanzhengma')"></el-input>
                            <div :class="{'getcode': true, 'noclick': !getcodecanClick}" 
                                data-type="4" 
                                @click="getcode('5')"> 
                                {{ getcodeTitle }} </div>
                        </div>
                    </div>
                    <div class="Change-cancel flex_row_center">
                        <div class="but-determine queding" @click="changefundpasswordClick"> {{ i18n('queding')}} </div>
                    </div>
                </div>
            </div>
        </el-dialog>

        <el-dialog 
        :title="i18n('youxiangrenzheng')"
        class="dialogyouxiangrenzheng" 
        :visible.sync="dialogyouxiangrenzheng" 
        :close-on-click-modal=false
        :close-on-press-escape=false
        width="30%">    
            <div class="flex_row_center" style="font-size: 14px;">
                <div style="width: 400px; background-color: #fff;">
                    <div class="Change-input" style="margin-bottom: 20px;"> 
                        {{ i18n('youxiangdizhi')}} <br>
                        <!-- <input type="tel" class="email" placeholder="请输入邮箱地址" /> -->
                        <el-input 
                            v-model="emailAddr" 
                            style="width: 400px; margin-top: 12px;"
                            :placeholder="i18n('qingshuruyouxiangdizhi')"></el-input>
                    </div>
                    <div class="Change-input" style="margin-bottom: 20px;"> 
                        {{ i18n('tuxingyanzhengma')}} <br>
                        <div class="flex_row_left">
                            <!-- <input type="text" class="emailimgcode flex1" placeholder="请输入图形验证码" />
                            <img onclick="getImgCode()" class="authimg" src="" alt=""> -->
                            <el-input 
                                v-model="imgcode" 
                                style="width: 220px; margin-top: 12px;"
                                :placeholder="i18n('qingshurutuxingyanzhengma')"></el-input>
                            <img @click="getImgCode" 
                                class="authimg" 
                                :src="imgsrc" alt="" 
                                style="cursor: pointer; margin-left: 20px;">
                        </div>
                    </div>
                    <div class="Change-input" style="margin-bottom: 20px;"> 
                        {{ i18n('yanzhengma')}} <br>
                        <div class="flex_row_left">
                            <!-- <input type="text" class="emailnumcode flex1" placeholder="请输入验证码" />
                            <div class="getemailcode">获取验证码</div> -->
                            <el-input 
                                v-model="emailtextcode" 
                                style="width: 220px; margin-top: 12px;"
                                :placeholder="i18n('qingshuruyouxiangyanzhengma')"></el-input>
                            <div :class="{'getcode': true, 'noclick': !getemailcodecanClick}" 
                                data-type="4" 
                                @click="getemailcode()"> 
                                {{ getemailcodeTitle }} </div>
                        </div>
                    </div>
                    <div class="Change-cancel flex_row_center">
                        <div class="but-determine emailqueding" @click="emailauthsubmit"> {{ i18n('queding')}} </div>
                    </div>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { teadeWSReq } from '@/utils/packedTrade'
import config from '@/utils/configs'

export default {
    data() {
        return {
            userName: undefined,
            uid: sessionStorage.getItem("uid"),
            shownickname: true,
            nickname: undefined,
            emailAuthentication: undefined,
            trueNameAuthentication: undefined,
            payAuthentication: undefined,
            dialogchangepassword: false,
            imgsrc: undefined,
            imgcodeid: undefined,
            oldpassword: undefined,
            newpassword: undefined,
            newpassword2: undefined,
            imgcode: undefined,
            numcode: undefined,
            phonetextcodecid: undefined,
            userPhoneNum: undefined,
            getcodecanClick: true,
            count: 0,
            countDowntimer: null,
            getcodeTitle: this.$t('SecurityCenter')['huoqushoujiyanzhengma'],
            dialogchangefundpassword: false,
            fundPassword: undefined,
            fundPassword2: undefined,
            dialogyouxiangrenzheng: false,
            getemailcodecanClick: true,
            emailcount: 0,
            emailcountDowntimer: null,
            getemailcodeTitle: this.$t('SecurityCenter')['huoquyouxiangyanzhengma'],
            emailtextcode: undefined,
            emailtextcodecid: undefined,
            emailAddr: undefined,
            headimgShow: false,
            headimg: require('@/assets/images/touxiang.png'),          
            uploadURL: config.apiAddress + '/Home/Register/loadPic',
        };
    },
    mounted() {
        this.$root.$on('tradelogin', (message) => {
            if (message.loginStatus) {
                this.getUserPublicInfo();
                this.getAuthenticationInfo();
                this.getUserAuthPhone();
            } 
        });

        //判断一下套接字的连接状态
        if (this.$store.state.loginStatus) {
            this.getUserPublicInfo();
            this.getAuthenticationInfo();
            this.getUserAuthPhone();
        };

        this.$root.$on('TS2CModifyPwdRes', (resultCode) => {
            if (resultCode === 0) {
                this.$message({
                    message: this.$t('SecurityCenter')['denglumimaxiugaichenggong'],
                    type: 'warning'
                });
                this.oldpassword = undefined;
                this.newpassword = undefined;
                this.newpassword2 = undefined;
                this.imgcode = undefined;
                this.numcode = undefined;
                this.dialogchangepassword = false;
           } else {
                this.$message({
                    message: this.$t('SecurityCenter')['denglumimaxiugaishibai'],
                    type: 'warning'
                });
                this.imgcode = undefined;
                this.numcode = undefined;
           }
        });
    },
    beforeDestroy() {
        this.$root.$off('tradelogin');
        this.$root.$off('TS2CModifyPwdRes');
    },
    computed: {
        i18n() {
            return function(name) {
                // index找到对应页面的obj name是onj的key
                return this.$t("SecurityCenter")[name];
            }
		},
    },
    methods: {
        goBack() {
            this.$router.go(-1);
        },
        nicknameClick() {
            this.shownickname = !this.shownickname;
        },
        async changeNikname() {
            if (this.nickname !== undefined && this.nickname !== '') {
                let data = {
                    username: this.nickname,
                }
                this.$api.nickNameEdit(data).then((res)=>{
                    if(res.data.code == 200){
                        this.userName = this.nickname;
                        this.shownickname = !this.shownickname;
                    } else {
                        this.$message({
                            message: this.$t('SecurityCenter')['nichengxiugaishibai'],
                            type: 'warning'
                        });
                    }
                });
            } else {
                this.$message({
                    message: this.$t('SecurityCenter')['qingshurunicheng'],
                    type: 'warning'
                });
            }
        },
        async getUserPublicInfo() {
            let data = {}
            this.$api.getUserPublicInfo(data).then((res)=>{
                if(res.data.code == 200){
                    this.userName = res.data.data.name;
                    this.nickname = res.data.data.name;
                    if (res.data.data.profilePicture && res.data.data.profilePicture != '') {
                        this.headimg = config.baseApiAddress + res.data.data.profilePicture;
                    }
                } else {
                    this.$message({
                        message: this.$t('SecurityCenter')['huoquyonghuxinxishibai'],
                        type: 'warning'
                    });
                }
            });
        },
        async getAuthenticationInfo() {
            let data = {}
            this.$api.getAuthenticationInfo(data).then((res)=>{
                if(res.data.code == 200){
                    // phoneAuthentication
                    this.emailAuthentication = res.data.data.emailAuthentication;
                    this.trueNameAuthentication = res.data.data.trueNameAuthentication;
                    this.payAuthentication = res.data.data.payAuthentication;
                } else {
                    this.$message({
                        message: this.$t('SecurityCenter')['huoquyonghuxinxishibai'],
                        type: 'warning'
                    });
                }
            });
        },
        changepassword() {
            this.getImgCode();
            this.dialogchangepassword = true;
        },
        async getImgCode() {
            let data = {}
            this.$api.getmakeVerifyBase64(data).then((res)=>{
                if(res.data.code == 200){
                    this.imgsrc = res.data.img_base64;
                    this.imgcodeid = res.data.id;
                } else {
                    this.$message({
                        message: this.$t('SecurityCenter')['huoqutuxingyanzhengmashibaiqingchongxinqingqiu'],
                        type: 'warning'
                    });
                }
            });
        },
        getcode(codeuse) {
            if (this.userPhoneNum !== undefined && this.userPhoneNum !== '' && this.userPhoneNum !== this.$t('SecurityCenter')['weirenzheng']) {
                if (this.imgcode !== undefined && this.imgcode !== '') {
                    this.getPhoneTextCode(this.imgcode, codeuse, this.userPhoneNum, this.imgcodeid);
                } else {
                    this.$message({
                        message: this.$t('SecurityCenter')['qingshurutuxingyanzhengma'],
                        type: 'warning'
                    });
                }
            } else {
                this.$message({
                    message: this.$t('SecurityCenter')['qingxianwanchengshoujirenzheng'],
                    type: 'warning'
                });
            }
        },
        async getPhoneTextCode(textcode, codeuse, userphone, imgcodeid) {
            let data = {
                textcode: textcode,
                codeuse: codeuse,
                userphone: userphone,
                id: imgcodeid,
            }
            this.$api.getPhoneTextCode(data).then((res)=>{
                if(res.data.code == 200){
                    this.phonetextcodecid = res.data.cid;
                    this.countDown();
                } else {
                    this.$message({
                        message: this.$t('SecurityCenter')['huoqushoujiyanzhengmashibai'],
                    })
                }
            })
        },
        countDown() {
            this.count = 60;
            clearInterval(this.countDowntimer);
            this.countDowntimer = setInterval(() => {
                this.count--;
                this.getcodeTitle = this.count + this.$t('SecurityCenter')['houchongxinfasong'] ;
                if (this.count === 0) {
                    this.count = 60;
                    this.getcodecanClick = true;
                    clearInterval(this.countDowntimer);
                    this.getcodeTitle = this.$t('SecurityCenter')['huoqushoujiyanzhengma'] ;
                }
            }, 1000);
        },
        async getUserAuthPhone() {
            let data = {}
            this.$api.getUserAuthPhoneAndEmail(data).then((res)=>{
                if(res.data.code == 200){
                    this.userPhoneNum = res.data.data.phone;
                } else {
                    this.$message({
                        message: this.$t('SecurityCenter')['huoquzhuceshoujihaoshibai'],
                        type: 'warning'
                    });
                }
            });
        },
        changePswclick() {
            if (this.numcode !== undefined && this.numcode !== '') {
                if (this.oldpassword !== undefined && this.oldpassword !== '' && this.newpassword !== undefined && this.newpassword !== '' && this.newpassword2 !== undefined && this.newpassword2 !== '') {
                    if (this.newpassword === this.newpassword2) {
                        if (this.oldpassword !== this.newpassword2) {
                            this.textCodeCheck(this.numcode, this.phonetextcodecid, 'changepassword');
                        } else {
                            this.$message({
                                message: this.$t('SecurityCenter')['xinmimaheyuanmimabunengyiyang'],
                                type: 'warning'
                            });
                        }
                    } else {
                        this.$message({
                            message: this.$t('SecurityCenter')['liangcixinmimashurubuyizhiqingjiancha'],
                            type: 'warning'
                        })
                    }
                }
            } else {
                this.$message({
                    message: this.$t('SecurityCenter')['qingshurushoujiyanzhengma'],
                    type: 'warning'
                })
            }
           
        },
        async textCodeCheck(textcode, cid, type) {
            let data = {
                cid: cid,
                textcode: textcode,
            }
            this.$api.textCodeCheck(data).then((res)=>{
                if(res.data.code == 200) {
                    if (type === 'changepassword') {
                        teadeWSReq.changepwd(this.oldpassword, this.newpassword);
                    }

                    if (type === 'changefundpassword') {
                        this.editFundPwd(res.data.cid, this.fundPassword);
                    }

                    if (type === 'emailauth') {
                        this.emailAddr = undefined;
                        this.imgcode = undefined;
                        this.emailtextcode = undefined;
                        this.getAuthenticationInfo();
                        this.dialogyouxiangrenzheng = false;
                        this.$message({
                            message: this.$t('SecurityCenter')['youxiangyanzhengchenggong'],
                            type: 'warning'
                        });
                    }
                } else {
                    this.$message({
                        message: this.$t('SecurityCenter')['yanzhengmacuowu'] + res.data.msg,
                        type: 'warning'
                    });
                }
            })
        },
        changefundpassword() {
            this.getImgCode();
            this.dialogchangefundpassword = true;
        },
        changefundpasswordClick() {
            if (this.payAuthentication === 1 || this.payAuthentication === 3) {
                //修改资金密码
                if (this.numcode !== undefined && this.numcode !== '') {
                    if (this.fundPassword !== undefined && this.fundPassword !== '' && this.fundPassword2 !== undefined && this.fundPassword2 !== '') {
                        if (this.fundPassword === this.fundPassword2) {
                            this.textCodeCheck(this.numcode, this.phonetextcodecid, 'changefundpassword');
                        } else {
                            this.$message({
                                message: this.$t('SecurityCenter')['liangcizijinmimabuyizhiqingjiancha'],
                                type: 'warning'
                            })
                        }
                    } else {
                        this.$message({
                            message: this.$t('SecurityCenter')['qingshuruzijinmima'],
                            type: 'warning'
                        })
                    }
                } else {
                    this.$message({
                        message: this.$t('SecurityCenter')['qingshurushoujiyanzhengma'],
                        type: 'warning'
                    })
                }
            } else {
                //设置资金密码
                if (this.fundPassword !== undefined && this.fundPassword !== '' && this.fundPassword2 !== undefined && this.fundPassword2 !== '') {
                    if (this.fundPassword === this.fundPassword2) {
                        this.addFundPwd(this.fundPassword);
                    } else {
                        this.$message({
                            message: this.$t('SecurityCenter')['liangcizijinmimabuyizhiqingjiancha'],
                            type: 'warning'
                        })
                    }
                } else {
                    this.$message({
                        message: this.$t('SecurityCenter')['qingshuruzijinmima'],
                        type: 'warning'
                    })
                }
            }
            
        },
        async addFundPwd(userfundpass) {
            let data = {
                userfundpass: userfundpass,
            }
            this.$api.addFundPwd(data).then((res)=>{
                if(res.data.code == 200) {
                    this.$message({
                        message: this.$t('SecurityCenter')['shezhizijinmimachenggong'],
                        type: 'warning'
                    });
                    this.fundPassword = undefined;
                    this.fundPassword2 = undefined;
                    this.dialogchangefundpassword = false;
                } else {
                    this.$message({
                        message: this.$t('SecurityCenter')['shezhizijinmimashibai'] + res.data.msg,
                        type: 'warning'
                    });
                }
            })
        },
        async editFundPwd(cid, userfundpass) {
            let data = {
                cid: cid,
                userfundpass: userfundpass,
            }
            this.$api.editFundPwd(data).then((res)=>{
                if(res.data.code == 200) {
                    this.$message({
                        message: this.$t('SecurityCenter')['xiugaizijinmimachenggong'],
                        type: 'warning'
                    });
                    this.fundPassword = undefined;
                    this.fundPassword2 = undefined;
                    this.dialogchangefundpassword = false;
                } else {
                    this.$message({
                        message: this.$t('SecurityCenter')['xiugaizijinmimashibai'] + res.data.msg,
                        type: 'warning'
                    });
                }
            })
        },
        getemailcode() {
            if (this.emailAddr !== undefined && this.emailAddr !== '') {
                if (this.imgcode !== undefined && this.imgcode !== '') {
                    this.getEmailTextCode(this.imgcode, '6', this.emailAddr, this.imgcodeid);
                } else {
                    this.$message({
                        message: this.$t('SecurityCenter')['qingshurutuxingyanzhengma'],
                        type: 'warning'
                    });
                }
            } else {
                this.$message({
                    message: this.$t('SecurityCenter')['qingxianwanchengyouxiangrenzheng'],
                    type: 'warning'
                });
            }
        },
        emailcountDown() {
            this.emailcount = 60;
            clearInterval(this.emailcountDowntimer);
            this.emailcountDowntimer = setInterval(() => {
                this.emailcount--;
                this.getemailcodeTitle = this.emailcount + this.$t('SecurityCenter')['houchongxinfasong'] ;
                if (this.emailcount === 0) {
                    this.emailcount = 60;
                    this.getemailcodecanClick = true;
                    clearInterval(this.emailcountDowntimer);
                    this.getemailcodeTitle = this.$t('SecurityCenter')['huoquyouxiangyanzhengma'] ;
                }
            }, 1000);
        },
        emailauthClick() {
            this.getImgCode();
            this.dialogyouxiangrenzheng = true;
        },
        async getEmailTextCode(textcode, codeuse, useremail, imgcodeid) {
            let data = {
                textcode: textcode,
                codeuse: codeuse,
                useremail: useremail,
                id: imgcodeid,
            }
            this.$api.getEmailTextCode(data).then((res)=>{
                if(res.data.code == 200){
                    this.emailtextcodecid = res.data.cid;
                    this.emailcountDown();
                } else {
                    this.$message({
                        message: this.$t('SecurityCenter')['huoquyouxiangyanzhengmashibai'],
                    })
                }
            })
        },
        emailauthsubmit() {
            if (this.emailtextcode !== undefined && this.emailtextcode !== '') {
                this.textCodeCheck(this.emailtextcode, this.emailtextcodecid, 'emailauth');
            } else {
                this.$message({
                    message: this.$t('SecurityCenter')['qingshuruyouxiangyanzhengma'],
                    type: 'warning'
                })
            }
        },
        verificationclick() {
            this.$router.push({name:'verificationLayout'});
        },
        headimgenter() {
            this.headimgShow = true;
        },
        headimgover() {
            this.headimgShow = false;
        },
        handleAvatarSuccess(res, file) {
            this.headimg = config.baseApiAddress + res.file.imgPath;
            this.changeHeadimg(res.file.imgPath);
        },
        beforeAvatarUpload(file) {
            const isJPG = file.type === 'image/jpeg';
            const isLt2M = file.size / 1024 / 1024 < 2;

            if (!isJPG) {
                // this.$message.error('上传头像图片只能是 JPG 格式!');
                this.$message({
                    message: this.$t('SecurityCenter')['shangchuantouxiangzhinengshijpggeshi'],
                    type: 'warning'
                });
            }
            if (!isLt2M) {
                // this.$message.error('上传头像图片大小不能超过 2MB!');
                this.$message({
                    message: this.$t('SecurityCenter')['shangchuantouxiangtupiandaxiaobunengchaoguo2M'],
                    type: 'warning'
                });
            }
            return isJPG && isLt2M;
        },
        changeHeadimg(img) {
            let data = {
                userpicture: img,
            }
            this.$api.userPictureEdit(data).then((res)=>{
                if(res.data.code == 200) {
                    this.$message({
                        message: this.$t('SecurityCenter')['xiugaitouxiangchenggong'],
                        type: 'warning'
                    });
                    this.getUserPublicInfo();
                } else {
                    this.$message({
                        message: this.$t('SecurityCenter')['xiugaitouxiangshibai'],
                        type: 'warning'
                    });
                    this.getUserPublicInfo();
                }
            })
        },
    },
}
</script>

<style>

.dialogyouxiangrenzheng .el-dialog,
.dialogchangefundpassword .el-dialog,
.dialogchangepassword .el-dialog {
    background: #fff !important;
}

.dialogyouxiangrenzheng .el-dialog__title,
.dialogchangefundpassword .el-dialog__title,
.dialogchangepassword .el-dialog__title {
    color: #999 !important;
    font-weight: 900;
}

</style>

<style scoped>

.SecurityCenter {
    background: #F9F9FA;
    height: auto;
    min-width: 1200px;
    margin: 0 auto;
}

.list {
    min-width: 1200px;
    width: 100%;
    background-color: #1B2945;
}

.list-top {
    min-width: 1200px;
    width: 1150px;
    margin: 0 auto;
    padding: 52px 0px;
    display: flex;
}

.touxiang {
    margin-right: 28px;
    margin-left: 50px;
    width: 56px;
    height: 56px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    overflow: hidden;
    border: 1px solid #475776;
}

.he {
    height: 35px;
}

h2 {
    font-size: 26px;
    color: #fff;
    float: left;
    font-weight: 300;
}

.list-id {
    height: 22px;
    border: 1px solid #455575;
    color: #fff;
    border-radius: 20px;
    /* padding: 5px 5px 5px 5px; */
}

.list-shi p {
    font-size: 12px;
    float: left;
    color: #798296;
}

.list-btton {
    min-width: 1200px;
    width: 100%;
    background-color: #FFFFFF;
    padding-top: 40px;
}

.iconfont {
    font-size: 20px;
    color: #6BD6C0;
    margin-right: 12px;
}

.color_white {
    color: #FFFFFF;
}

.fontSize16 {
    font-size: 16px;
}

.abinput1 {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    /* display: none; */
}

.pos_relative {
    position: relative;
}

.surebtn {
    height: 30px;
    width: 50px;
    line-height: 30px;
    padding: 0 12px;
    text-align: center;
    margin-left: 12px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    cursor: pointer;
}

.bg_green {
    background: #0059FF;
}

.nickinput {
    background: #233558;
    border: none;
    outline: none;
    height: 30px;
    line-height: 30px;
    width: 150px;
}

.nickinput .el-input__inner{
    border: none;
    color:#eee;
    background-color: #233558;
}

.list-btton {
    min-width: 1200px;
    width: 100%;
    background-color: #fff;
    padding-top: 40px;
}

.security {
    min-width: 1200px;
    width: 1150px;
    margin: 0 auto;
    color: #F2F6FA;
}

.security-top {
    border: 1px solid #E6ECF2;
    padding: 16px 0px 17px 25px;
    font-size: 16px;
    color: #333333;
}

.security-but {
    padding: 37px 40px;
    border: 1px solid #E6ECF2;
}

.img-deng {
    float: left;
    margin-top: 10px;
    margin-right: 20px;
    font-size: 32px;
}

.security-but ul li p {
    font-size: 16px;
    color: #333;
}

.security-but ul li {
    border-bottom: 1px solid #E6ECF2;
    padding-bottom: 20px;
    height: 50px;
    margin-bottom: 24px;
}

.modify {
    float: right;
    width: 60px;
    height: 28px;
    background-color: #08BD98;
    color: #fff;
    margin-top: -40px;
    border-radius: 2px;
    cursor: pointer;
}

.alreadyCertify {
    font-size: 14px;
    font-weight: 400;
    color: #666666;
    line-height: 34px;
    float: right;
    margin-top: -40px;
}

.confuse {
    margin-right: 70px;
    color: #E27476;
}

.Change-cancel {
    text-align: right;
}

.but-determine {
    height: 40px;
    text-align: center;
    line-height: 40px;
    background-color: #08BD98;
    color: #fff;
    width: 126px;
    border-radius: 2px;
    cursor: pointer;
}

.noclick {
    pointer-events: none;
    background: rgb(208, 217, 222);
    border: none;
}

.flex_row_left {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.flex_row_around {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.flex_row_center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.flex_row_between {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.flex_col_center {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.getcode {
    background: #08BD98;
    box-shadow: 0px 2px 4px 0px rgb(21 85 73 / 35%);
    color: #fff;
    line-height: 40px;
    text-align: center;
    margin-top: 12px;
    width: 160px;
    margin-left: 10px;
    cursor: pointer;
}

</style>
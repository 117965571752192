<template>
    <div>
        <!-- 列表部分 -->
        <div class="assets">
            <div class="head-part">
                <div class="head-top">
                    <div class="flex_col_center" style="align-items: flex-start;">
                        <div class="title"> {{ i18n('zongzichan') }} (USDT)</div>
                        <div class="title-desc"> {{ userWallet.marginBalance + userWallet.LegalCurrency | filter_moneyFormat(8) }} </div>
                    </div>
                    <div class="flex_row_right flex1">
                        <div class="btn" @click="rechargeClick"> {{ i18n('chongbi') }} </div>
                        <div class="btn" @click="withdrawClick"> {{ i18n('tibi') }} </div>
                        <div class="btn" @click="showTrans"> {{ i18n('huazhuan') }} </div>
                    </div>
                </div>
            </div>
            <div class="content-wrap">
                <div class="flex_row_between">
                    <div class="scard flex_col_center">
                        <div class="flex_row_between width100">
                            <div class="color_666 fontSize20 flex1"> {{ i18n('fabizhanghu') }} (USDT)</div>
                            <span class="flex_row_left color_green bill" @click="BasicsAccountRecordClick">
                                <i class="el-icon-document"></i>
                                {{ i18n('jiayiliushui') }}
                            </span>
                            <!-- <a class="flex_row_left color_green bill" data-v-f36733ea="" href="otcBill.html">
                            </a> -->
                        </div>
                        <div class="hyzh fontSize25"> {{ userWallet.LegalCurrency | filter_moneyFormat(8) }} </div>
                    </div>
                </div>
                
                <div class="flex_row_between" style="margin-top: 20px;">
                    <div class="scard flex_col_center">
                        <div class="flex_row_between width100">
                            <div class="color_666 fontSize20 flex1"> {{ i18n('heyuezhanghu') }} (USDT)</div>
                            <span class="flex_row_left color_green bill" @click="ContractAccountRecordClick">
                                <i class="el-icon-document"></i>
                                {{ i18n('jiayiliushui') }}
                            </span>
                            <!-- <a class="flex_row_left color_green bill" data-v-f36733ea="" href="tradeBill.html">
                            </a> -->
                        </div>
                        <div class=" usdtzh fontSize25"> {{ userWallet.marginBalance | filter_moneyFormat(8) }} </div>
                    </div>
                </div>
                
            </div>

        </div>
        <!-- 划转 -->
        <el-dialog :title="i18n('dialogzichanhuazhuan')" class="dialogtransfer assetsdialog" :visible.sync="dialogtransfer" width="450px">
            <!-- 从到 -->
            <div class="flex_row_around color_aaa">
                <div class="flex1"> {{ i18n('cong') }} </div>
                <div class="transfericon"></div>
                <div class="flex1"> {{ i18n('dao') }} </div>
            </div>
            <div class="flex_row_around">
                <div class="from flex_row_left flex1">
                    <div class="flex1" style="color: #000;"> {{ transferfromvalue }} </div>
                </div>
                <img class="transfericon" :src="require('@/assets/images/transfer_b.png')" alt="" @click="transfericonClick">
                <div class="to flex_row_left flex1">
                    <div class="flex1" style="color: #000;"> {{ transfertovalue }} </div>
                </div>
            </div>
            <!-- 数量 -->
            <div class="color_aaa"> {{ i18n('shuliang') }} </div>
            <div class="flex_row_left trsnum">
                <el-input-number v-model="transferNum" size="mini" 
                    :precision="8" :step="1" :min="1" :max="transdirection === 0 ? this.userWallet.LegalCurrency : this.userWallet.marginAvailable"
                    style="margin-left: 20px; background-color: #fff; width: 60%; margin-right: 20px;" 
                    :placeholder="i18n('qingshuruhuazhuanshuliang')"></el-input-number>
                <div class="currency-show" style="color: #000;">USDT</div>
                <div class="color_green tarnsall" style="margin-left: 10px; cursor: pointer;" @click="transferAllclick"> {{i18n('quanbu')}} </div>
            </div>
            <div class="fontSize12 color_aaa tipss"> {{i18n('keyong')}} {{ (transdirection === 0 ? this.userWallet.LegalCurrency : this.userWallet.marginAvailable) | filter_moneyFormat(8) }} </div>
            <div class="transferbtn" @click="transferbtnclick"> {{i18n('huazhuan')}} </div>
        </el-dialog>
    </div>
</template>

<script>
import { moneyFormat } from '@/utils/filters.js'
import { teadeWSReq } from '@/utils/packedTrade'

export default {
    data() {
        return {
            userWallet: this.$store.state.userWallet,
            dialogtransfer: false,
            transferfromvalue: this.$t('assets')['fabizhanghu'],
            transfertovalue: this.$t('assets')['heyuezhanghu'],
            transferNum: undefined,
            transdirection: 0,
        };
    },
    mounted() {
        this.$root.$on('TS2CTransferCapitalRes', (msg) => {
            if (this.dialogtransfer) {
                if (msg.Resultcode == 0) {
                    this.$message({
                        message: this.$t('assets')['zijinhuazhuanchenggong'],
                        type: 'success'
                    });
                    this.$store.dispatch('getMyLegalCurrencyAccountInfo');
                    this.transferNum = undefined;
                    this.dialogtransfer = false;
                } else {
                    this.$message({
                        message: this.$t('assets')['zijinhuazhuanshibai'],
                        type: 'success'
                    });
                }
            }
        });
    },
    beforeDestroy() {
        this.$root.$off('TS2CTransferCapitalRes');
    },
    filters: {
        filter_moneyFormat: function (x, n) {
            return moneyFormat(x, n);
        },
    },
    methods: {
        rechargeClick() {
            this.$router.push("/rechargeLayout");
        },
        withdrawClick() {
            this.$router.push("/withdrawLayout");
        },
        showTrans() {
            this.dialogtransfer = true;
        },
        transfericonClick() {
            var temp = this.transfertovalue;
            this.transfertovalue = this.transferfromvalue;
            this.transferfromvalue = temp;
            if (this.transdirection === 0) {
                this.transdirection = 1;
            } else {
                this.transdirection = 0;
            }
            this.transferNum = undefined;
        },
        transferAllclick() {
            if (this.transdirection === 0) {
                if (this.userWallet.LegalCurrency > 0) {
                    this.transferNum = this.userWallet.LegalCurrency;
                } else {
                    this.transferNum = undefined;
                }
            } else {
                if (this.userWallet.marginAvailable > 0){
                    this.transferNum = this.userWallet.marginAvailable;
                } else {
                    this.transferNum = undefined;
                }
            }
        },
        transferbtnclick() {
            if (this.transferNum && this.transferNum > 0) {
                teadeWSReq.exchangeCurrency(this.transdirection, this.transferNum);
            } else {
                this.$message({
                    message: this.$t('assets')['qingshuruhuazhuanshuliang'],
                    type: 'warning'
                });
            }
        },
        BasicsAccountRecordClick() {
            this.$router.push("/BasicsAccountRecordLayout");
        },
        ContractAccountRecordClick() {
            this.$router.push("/contractAccountRecordLayout");
        },
        selectFromChange() {
            this.transferNum = undefined;
        },
    },
    computed: {
        i18n() {
            return function(name) {
                // index找到对应页面的obj name是onj的key
                return this.$t("assets")[name];
            }
		},
    },
}
</script>

<style>
.assetsdialog .el-dialog  {
    background: #fff !important;
}

.assetsdialog .el-dialog__title {
    color: #000 !important;
    font-weight: 900;
}

.assetsdialog .el-input__inner {
    border: none;
    background: white !important;
    color: #333  !important;
}

</style>

<style scoped>
@import "../css/assetsLayout.css";

.fontSize20 {
    font-size: 20px;
}

.fontSize25 {
    font-size: 25px;
}

.color_green {
    color: #08BD98;
}

.flex_row_between {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.flex_col_center {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.flex_row_left {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.flex_row_around {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.flex_row_center {
    display: flex;
    justify-content: center;
    align-items: center;
}

::v-deep .assetsdialog .el-input-number__decrease{
    border: unset;
    color: unset;
    background-color: #eee;
}

::v-deep .assetsdialog .el-input-number__increase{
    border: unset;
    color: unset;
    background-color: #eee;
}

::v-deep .assetsdialog .el-input-number__decrease.is-disabled{
    color: #ccc;
}

::v-deep .assetsdialog .el-input-number__increase.is-disabled{
    color: #ccc;
}

::v-deep .assetsdialog .el-input-number.is-disabled .el-input-number__decrease{
    color: #ccc;
}

::v-deep .assetsdialog .el-input-number.is-disabled .el-input-number__increase{
    color: #ccc;
}

</style>
<template>
    <div id="app">
        <router-view></router-view>
    </div>
</template>

<script>
import config from '@/utils/configs'

export default {
    name: 'app',
    created() {
        this.$store.dispatch('quotesWS/startWebSocket', config.quotationAddress);

        //自动登录
        let username = sessionStorage.getItem('username');
        let userpassword = sessionStorage.getItem('userpass');
        if (username && userpassword && username !== '' && userpassword !== '') {
            let conn_data = {
                url: config.tradeAddress,
                user: username,
                userpass: userpassword
            }
            this.$store.dispatch('tradeWS/startWebSocket', conn_data);
        }
    },
    beforeDestroy() {
        this.$store.dispatch('quotesWS/close');
        this.$store.dispatch('tradeWS/close');
    }
}
</script>
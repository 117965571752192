import { teadeWSparse } from '@/utils/parseTrade'
import { teadeWSReq } from '@/utils/packedTrade'
import { DecodePack } from '@/utils/ByteArray.js'
import store from '@/store'
import router from '../router'
import config from '@/utils/configs'

const tradeWSModule = {
    namespaced: true,
    state: {
        ws: null,
        wsHart: 0,
    },
    mutations: {
        SET_WS(state, ws) {
            state.ws = ws;
            // console.log("SET_WS", state.ws);
        },
        CLEAR_WS(state) {
            state.ws = null;
        },
        INC_HARTCOUNT(state) {
            state.wsHart++;
        },
        ZERO_HARTCOUNT(state) {
            state.wsHart = 0;
        },
    },
    actions: {
        startWebSocket({commit, state}, connData) {
            // commit('SET_LOGINDATA', loginMsg);
            if (state.ws) state.ws.close();
            const ws = new WebSocket(connData.url);
            ws.onopen = function () {
                console.log('trade WebSocket connected');

                commit('SET_WS', ws);
        
                teadeWSReq.login(connData.user, connData.userpass);
            };
            ws.onmessage = function (event) {
                // console.log('Received:', event.data);
                // 处理接收到的消息

                // let message = {
                //     loginStatus: true,
                // }
                // this.$emit('trade:11001', message);

                var recvData = event.data;
                // 将 Blob 转换为 ArrayBuffer
                if (recvData instanceof Blob) {
                    var reader = new FileReader();
                    reader.onload = function() {
                        var  arrayBuffer = this.result;
                        var bbt = DecodePack(arrayBuffer);
                        teadeWSparse.parseData(bbt);
                    };
                    reader.readAsArrayBuffer(recvData);
                } else if (recvData instanceof ArrayBuffer) {
                    var bbt = DecodePack(recvData);
                    teadeWSparse.parseData(bbt);
                } else {
                    console.error("Unsupported data type in event.data");
                }
            };
            ws.onerror = function (error) {
                console.error('trade WebSocket error:', error);
            };
            ws.onclose = function (error) {
                console.log('trade WebSocket closed');
                //清空Store里的数据
                store.dispatch('ClearContractState');
                if (error.code != 1005) {
                    router.push({
                        path:'/LoginLayout'
                    });
                }

                // 重连
                let username = sessionStorage.getItem('username');
                let userpassword = sessionStorage.getItem('userpass');
                if (username && userpassword && username !== '' && userpassword !== '') {
                    store.dispatch('tradeWS/reconn');
                }    
            };
        },
        sendMessage({state}, message) {
            if (state.ws && state.ws.readyState === WebSocket.OPEN) {
                state.ws.send(message);
            } else {
                console.warn('WebSocket not connected, unable to send message');
            }
        },
        close({state}) {
            if (state.ws) state.ws.close();
        },
        reconn() {
            var interval = setInterval(function(){
                if (store.state.tradeWS.ws.readyState === WebSocket.OPEN) {
                    clearInterval(interval);
                } else {
                    let username = sessionStorage.getItem('username');
                    let userpassword = sessionStorage.getItem('userpass');
                    let conn_data = {
                        url: config.tradeAddress,
                        user: username,
                        userpass: userpassword
                    };
                    store.dispatch('tradeWS/startWebSocket', conn_data);
                }
            }, 5000)
        },
    }
}

export default tradeWSModule


